import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

const About = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const styles = {
    corn_img: {
      width: "100%",
      height: isSmallScreen ? "36vh" : "75vh",
    },
    paragraph: {
      textAlign: "center",
    },
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ maxWidth: "100% ", margin: "0", padding:'0px !important' }} maxWidth="false">
        <Box
          style={{
            adding: "0",
            width: "100%",
            height: isSmallScreen ? "36vh" : "75vh",
            padding:'0px'
          }}
        >
          <img style={styles.corn_img} src="../Assets/corn.png" />
        </Box>
      </Container>
      <Container sx={{ marginY: "5rem" }}>
        <Typography
          variant="h3"
          style={{ textAlign: "center", margin: "2rem", fontWeight: 700 }}
        >
          About Us
        </Typography>
        <Box sx={styles.paragraph}>
          <Typography variant="h5" paragraph sx={styles.paragraph}>
            Welcome to Millet Heaven, your ultimate destination for wholesome
            health mixes crafted from the finest millets, herbs, and dry fruits.
            Our mission is to bring the goodness of nature to your doorstep,
            offering products that not only taste great but also contribute to a
            healthier lifestyle.
          </Typography>
        </Box>
        <Box sx={styles.paragraph}>
          <Typography variant="h5">Who We Are At Millet Heaven,</Typography>
          <Typography component={"div"} paragraph>
            we are passionate about health and wellness. Our journey began with
            a simple idea: to create nutritious and delicious health mixes using
            the most beneficial ingredients that nature provides. Sponsored by
            Unic Health Products, we are committed to maintaining the highest
            standards of quality and purity in every product we offer.
          </Typography>
        </Box>
        <Box sx={styles.paragraph}>
          <Typography variant="h5" component={"div"}>
            What We Offer?
          </Typography>
          <Typography component={"div"} paragraph sx={styles.paragraph}>
            Our range of health mixes is thoughtfully formulated to cater to
            various health needs. Each blend combines the nutritional power of
            millets with the healing properties of herbs and the energy-boosting
            benefits of dry fruits. Whether you are looking for a nutritious
            breakfast option, a mid-day snack, or a post-workout boost, Millet
            Heaven has something for everyone.
          </Typography>
        </Box>
        <Box sx={styles.paragraph}>
          <Typography variant="h5" component={"div"}>
            Why Choose Millet Heaven?{" "}
          </Typography>
          <Typography component={"div"} paragraph sx={styles.paragraph}>
            Premium Quality Ingredients: We source the best millets, herbs, and
            dry fruits to ensure that you get the maximum health benefits in
            every serving. Nutrient-Rich: Our products are packed with essential
            vitamins, minerals, and antioxidants to support your overall
            well-being. Great Taste: Health should never compromise on taste.
            Our mixes are delicious and easy to incorporate into your daily
            routine. Innovative Health Products: Stay tuned as we continue to
            introduce unique health products designed to meet the evolving needs
            of our customers.
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default About;
