import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Rating,
  Select,
  MenuItem,
  Container,
  List,
  ListItem,
} from "@mui/material";
import React, { useRef, useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import useResponsive from "../../hooks/useResponsive";
import apiUrl from "../../config";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import { Context } from "../../context/UserContext";
import { Autoplay } from "swiper/modules";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const { userToken, products, isUserLoggedIn, UserProfile, selectprod } =
    useContext(Context);

  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState( products[selectprod]);  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedWeightIndex, setSelectedWeightIndex] = useState(0);
  const [reviews, setReviews] = useState(
    JSON.parse(localStorage.getItem("allreviews"))
  );
  const [open, setOpen] = useState(false);
  const [newReview, setNewReview] = useState({ rating: 0, description: "" });
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prev) => ({ ...prev, [name]: value }));
  };

  const handleRatingChange = (e, newValue) => {
    setNewReview((prev) => ({ ...prev, rating: newValue }));
  };

  const handleSubmitReview = async () => {
    try {
      const res = await axios.post(
        apiUrl + "postReview",
        { productId: selectedProduct.id, ...newReview },
        {
          headers: {
            token: userToken,
          },
        }
      );
      if (res.status == 200) {
        fetchReviews();
        setReviews([...reviews, res.data]);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const AddToCart = async () => {
    if (isUserLoggedIn) {
      try {
        const res = await axios.post(
          apiUrl + "addCart",
          {
            productId: parseInt(selectedProduct.id),
            quantity: selectedQuantity,
            price: JSON.parse(selectedProduct.quantity)[selectedWeightIndex]
              .discountPrice,
            size: JSON.parse(selectedProduct.quantity)[selectedWeightIndex]
              .weight,
          },
          {
            headers: {
              token: userToken,
            },
          }
        );
        if (res.data) {
          UserProfile();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };

  const increaseQuantity = () => {
    setSelectedQuantity((prev) => prev + 1);
  };

  const decreaseQuantity = () => {
    setSelectedQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const StarRating = (rating) => {
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5

    return Array.from({ length: 5 }, (_, index) => {
      if (index < roundedRating - 0.5) {
        return (
          <StarIcon key={index} sx={{ color: "#FFD700", marginLeft: "5px" }} />
        );
      } else if (index < roundedRating) {
        return (
          <StarIcon key={index} sx={{ color: "#FFD700", marginLeft: "5px" }} />
        );
      } else {
        return <span key={index} className="empty-star" />;
      }
    });
  };

  useEffect(() => {
    if (selectprod !== null && products.length > 0) {
      setSelectedProduct(products[selectprod]);
    }
  }, [selectedIndex, products]);


  const handprodselect=(id)=>{
    setSelectedProduct(products[id])
  }


  const fetchReviews = async () => {
    try {
      const response = await axios.get(apiUrl + "getreviews");
      localStorage.setItem("allreviews", JSON.stringify(response.data));
      setReviews(JSON.parse(localStorage.getItem("allreviews")));
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };
  useEffect(() => {
    fetchReviews();
  }, []);

  const handleWeightChange = (event) => {
    setSelectedWeightIndex(event.target.value); // Update selected weight index
  };

  const filteredReview = reviews?.filter(
    (review) => parseInt(review?.productId) === parseInt(selectedProduct?.id)
  );

  const averageRating =
    filteredReview?.length > 0
      ? filteredReview.reduce((sum, review) => sum + review.rating, 0) /
        filteredReview.length
      : 0;

  const renderStars = (rating) => {
    const totalStars = 5;
    const filledStars = Math.floor(rating); // Full stars count
    const remainder = rating - filledStars; // Decimal part to determine if there's a half star

    let stars = [];

    // Filled stars
    for (let i = 0; i < filledStars; i++) {
      stars.push(
        <StarIcon
          key={`star-filled-${i}`}
          sx={{
            color: "#FFD700",
            backgroundColor: "secondary.main",
            margin: "2px",
            borderRadius: "50%",
          }}
        />
      );
    }

    // Half star if needed
    if (remainder >= 0.25 && remainder <= 0.75) {
      stars.push(
        <StarIcon
          key={`star-half`}
          sx={{
            color: "#FFD700",
            backgroundColor: "secondary.main",
            margin: "2px",
            borderRadius: "50%",
          }}
        />
      );
    }
    // Empty stars
    const emptyStars = totalStars - stars.length;

    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <StarIcon
          key={`star-empty-${i}`}
          sx={{
            color: "#E0E0E0",
            backgroundColor: "secondary.main",
            margin: "2px",
            borderRadius: "50%",
          }}
        />
      );
    }

    return stars;
  };

  return (
    <Box>
      <Box>
        <Box sx={{ backgroundColor: "secondary.background" }}>
          {isSmallScreen || isMediumScreen ? (
            <Container
              sx={{
                display: isSmallScreen || isMediumScreen ? "block" : "none",
                padding: 2,
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-around"}
                sx={{ height: "268px" }}
              >
                <Box
                  sx={{
                    backgroundColor: "secondary.main",
                    paddingTop: 3.5,
                    paddingRight: 3.5,
                    paddingBottom: 3.5,
                    paddingLeft: 3.5,
                    borderRadius: isSmallScreen ? "10px" : 10,
                    alignSelf: "baseline",
                    display: "flex",
                    justifyContent: "center",
                    width: isSmallScreen ? "fit-content" : "auto",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      padding: isSmallScreen ? 1.5 : 2,
                      width: isMediumScreen ? "fit-content" : "inherit",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={selectedProduct?.image}
                      alt={selectedProduct?.title}
                      width={"114.28"}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "40%",
                    // background:"black"
                  }}
                >
                  <Button onClick={() => swiperRef.current?.slidePrev()}>
                    <ArrowCircleUpIcon
                      sx={{ color: "black", fontSize: "15PX" }}
                    />
                  </Button>
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={2}
                    direction={"vertical"}
                    centeredSlides={true}
                    loop={true}
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                    // breakpoints={{
                    //   640: {
                    //     slidesPerView: 3,
                    //     spaceBetween: 10,
                    //   },
                    //   768: {
                    //     slidesPerView: 2,
                    //     spaceBetween: 20,
                    //   },
                    //   1024: {
                    //     spaceBetween: 30,
                    //     slidesPerView: 3,
                    //   },
                    // }}
                  >
                    {products.map((val, index) => (
                      <SwiperSlide key={index} style={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            backgroundColor: "#ffffff",
                            padding: "5px",
                            marginBottom: "10px",
                            borderRadius: "5px",
                            height: "6.5rem",
                            width: "6.5rem",
                          }}
                          onClick={() => handprodselect(index)}
                        >
                          <img
                            src={val?.image}
                            style={{ width: "72px", height: "84px" }}
                            alt={val?.title}
                          />
                        </Box>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <Button onClick={() => swiperRef.current?.slideNext()}>
                    <ArrowCircleDownIcon sx={{ color: "black" }} />
                  </Button>
                </Box>
              </Box>
              <Typography variant="h4" sx={{ color: "#000" }}>
                {selectedProduct?.title}
              </Typography>

              <Box sx={{ marginY: "25px", display: "flex" }}>
                <Select
                  size="small"
                  value={selectedWeightIndex}
                  onChange={handleWeightChange}
                  sx={{ marginRight: 2 }}
                >
                  {selectedProduct &&
                    selectedProduct.quantity &&
                    JSON.parse(selectedProduct.quantity).map((val, index) => (
                      <MenuItem key={index} value={index}>
                        {val.weight}
                      </MenuItem>
                    ))}
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    width: "70px",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#000", marginLeft: "5px" }}
                  >
                    {averageRating.toFixed(1)}
                  </Typography>
                  <StarIcon
                    sx={{
                      marginBottom: "5px",
                      color: "#FFD700",
                      fontSize: "20px",
                      marginLeft: "5px",
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Box px={2} sx>
                  <Typography
                    variant="h6"
                    sx={{ marginTop: 3, fontWeight: "500" }}
                  >
                    Product Details
                  </Typography>
                  <Typography variant="h6">
                    {JSON.parseselectedProduct?.subtitle}
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    {selectedProduct?.nutritionInformation}
                  </Typography>
                  <List
                    sx={{
                      listStyleType: "disc",
                      listStylePosition: "inside",
                    }}
                  >
                    {selectedProduct.bulletins.split(", ").map((item) => (
                      <>
                        <ListItem sx={{ display: "list-item", padding: 1 }}>
                          {item}
                        </ListItem>
                      </>
                    ))}
                  </List>
                </Box>

                <Box sx={{ margin: 2, paddingX: 1, textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: isSmallScreen ? "column" : "row",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          textDecoration: "line-through",
                          marginRight: "15px",
                        }}
                      >
                        ₹
                        {
                          JSON.parse(selectedProduct?.quantity)[
                            selectedWeightIndex
                          ]?.originalPrice
                        }
                        {".00"}
                      </Typography>
                      <Typography variant="h6" sx={{ marginRight: "15px" }}>
                        ₹
                        {
                          JSON.parse(selectedProduct?.quantity)[
                            selectedWeightIndex
                          ]?.discountPrice
                        }
                        {".00"}
                      </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} my={2}>
                      <Typography
                        variant={isSmallScreen ? "body1" : "h6"}
                        textAlign={"center"}
                      >
                        Quantity{" "}
                      </Typography>
                      <Typography
                        sx={{
                          backgroundColor: "#ffff",
                          border: "1px solid black",
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "fit-content",
                          borderRadius: "15px",
                          marginLeft: "15px",
                          textAlign: "center",
                        }}
                      >
                        <RemoveIcon
                          onClick={decreaseQuantity}
                          sx={{ padding: "5px", cursor: "pointer" }}
                        />
                        {selectedQuantity}
                        <AddIcon
                          onClick={increaseQuantity}
                          sx={{ padding: "5px", cursor: "pointer" }}
                        />
                      </Typography>
                    </Box>
                  </Box>

                  {selectedProduct.instock ? (
                    <Button
                      onClick={AddToCart}
                      sx={{
                        backgroundColor: "btn_acc_2.background",
                        color: "btn_acc_2.main",
                        padding: "11px 64px",
                        borderRadius: "25px",
                        alignContent: "center",
                        marginTop: "25px",
                        "&:hover": {
                          backgroundColor: "btn_acc_2.background",
                          color: "btn_acc_2.main",
                        },
                      }}
                    >
                      Add to Cart
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        backgroundColor: "btn_acc_1.background",
                        color: "btn_acc_1.main",
                        padding: "11px 64px",
                        borderRadius: "25px",
                        alignContent: "center",
                        marginTop: "25px",
                        "&:hover": {
                          backgroundColor: "btn_acc_2.background",
                          color: "btn_acc_2.main",
                        },
                      }}
                    >
                      {selectedProduct.instock
                        ? "Available Now"
                        : "Out of Stock"}
                    </Button>
                  )}
                </Box>
              </Box>
            </Container>
          ) : (
            <>
              <Container
                sx={{
                  display: isSmallScreen
                    ? "none"
                    : isMediumScreen
                    ? "FLEX"
                    : "flex",
                  padding: isSmallScreen ? "5px" : 5,
                }}
              >
                <Box
                  sx={{
                    width: isMediumScreen ? "50%" : "100%",
                    backgroundColor: "secondary.main",
                    paddingTop: 5,
                    paddingRight: isMediumScreen ? 5 : 10,

                    paddingBottom: 5,

                    paddingLeft: isMediumScreen ? 5 : 10,

                    borderRadius: isSmallScreen ? "10px" : 10,
                    alignSelf: "baseline",
                    display: "flex",
                    justifyContent: "center",
                    width: isMediumScreen ? "50%" : "auto",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#fff",

                      padding: isSmallScreen ? 1.5 : 2,

                      width: isMediumScreen ? "fit-content" : "inherit",

                      display: "flex",

                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={selectedProduct?.image}
                      alt={selectedProduct?.title}
                      width={isSmallScreen ? "114.28" : "277"}
                    />
                  </Box>
                </Box>
                <Box sx={{ margin: 2, paddingX: isMediumScreen ? 2 : 5 }}>
                  <Typography variant="h3" sx={{ color: "#000" }}>
                    {selectedProduct?.title}
                  </Typography>

                  <Box sx={{ marginY: "25px", display: "flex" }}>
                    <Select
                      size="small"
                      value={selectedWeightIndex}
                      onChange={handleWeightChange}
                      sx={{ marginRight: 2 }}
                    >
                      {selectedProduct &&
                        selectedProduct.quantity &&
                        JSON.parse(selectedProduct?.quantity).map(
                          (val, index) => (
                            <MenuItem key={index} value={index}>
                              {val.weight}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        width: "70px",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#000", marginLeft: "5px" }}
                      >
                        {averageRating.toFixed(1)}
                      </Typography>
                      <StarIcon
                        sx={{
                          marginBottom: "5px",
                          color: "#FFD700",
                          fontSize: "20px",
                          marginLeft: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        textDecoration: "line-through",
                        marginRight: "15px",
                      }}
                    >
                      ₹
                      {
                        JSON.parse(selectedProduct && selectedProduct?.quantity)[
                          selectedWeightIndex
                        ]?.originalPrice
                      }
                      {".00"}
                    </Typography>
                    <Typography variant="h6" sx={{ marginRight: "15px" }}>
                      ₹
                      {
                        JSON.parse(selectedProduct?.quantity)[
                          selectedWeightIndex
                        ]?.discountPrice
                      }
                      {".00"}
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} my={2}>
                    <Typography variant="h6">Quantity :</Typography>
                    <Typography
                      sx={{
                        backgroundColor: "#ffff",
                        border: "1px solid black",
                        padding: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "fit-content",
                        borderRadius: "15px",
                        marginLeft: "15px",
                      }}
                    >
                      <RemoveIcon
                        onClick={decreaseQuantity}
                        sx={{ padding: "5px", cursor: "pointer" }}
                      />
                      {selectedQuantity}
                      <AddIcon
                        onClick={increaseQuantity}
                        sx={{ padding: "5px", cursor: "pointer" }}
                      />
                    </Typography>
                  </Box>
                  <Typography variant="h4" sx={{ marginTop: 3 }}>
                    Product Details
                  </Typography>
                  <Typography variant="h6">
                    {selectedProduct?.subtitle}
                  </Typography>
                  <Typography variant="body1">
                    {selectedProduct?.ingredients}
                  </Typography>
                  <List
                    sx={{
                      listStyleType: "disc",
                      listStylePosition: "inside",
                    }}
                  >
                    {selectedProduct.bulletins.split(", ").map((item) => (
                      <>
                        <ListItem sx={{ display: "list-item", padding: 1 }}>
                          {item}
                        </ListItem>
                      </>
                    ))}
                  </List>
                  {selectedProduct.instock ? (
                    <Button
                      onClick={AddToCart}
                      sx={{
                        backgroundColor: "btn_acc_2.background",
                        color: "btn_acc_2.main",
                        padding: "11px 64px",
                        borderRadius: "25px",
                        alignContent: "center",
                        marginTop: "25px",
                        "&:hover": {
                          backgroundColor: "btn_acc_2.background",
                          color: "btn_acc_2.main",
                        },
                      }}
                    >
                      Add to Cart
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        backgroundColor: "btn_acc_1.background",
                        color: "btn_acc_1.main",
                        padding: "11px 64px",
                        borderRadius: "25px",
                        alignContent: "center",
                        marginTop: "25px",
                        "&:hover": {
                          backgroundColor: "btn_acc_2.background",
                          color: "btn_acc_2.main",
                        },
                      }}
                    >
                      {selectedProduct.instock
                        ? "Available Now"
                        : "Out of Stock"}
                    </Button>
                  )}
                </Box>
              </Container>

              <Container>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: isSmallScreen ? "100%" : "48%",
                  }}
                >
                  <Button onClick={() => swiperRef.current?.slidePrev()}>
                    <ArrowCircleLeftOutlinedIcon
                      sx={{ color: "black", fontSize: "15PX" }}
                    />
                  </Button>
                  <Swiper
                    // spaceBetween={30}
                    // slidesPerView={3}
                    loop={true}
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                    breakpoints={{
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1024: {
                        spaceBetween: 30,
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {products.map((val, index) => (
                      <SwiperSlide
                        key={index}
                        style={{ width: isSmallScreen ? "130px" : "" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            backgroundColor: "#ffffff",
                            padding: "5px",
                            marginBottom: "10px",
                            borderRadius: "5px",
                            height: "7rem",
                            width: "7rem",
                          }}
                          onClick={() => handprodselect(index)}
                        >
                          <img
                            src={val?.image}
                            style={{ width: "98px", height: "113px" }}
                            alt={val?.title}
                          />
                        </Box>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <Button onClick={() => swiperRef.current?.slideNext()}>
                    <ArrowCircleRightOutlinedIcon sx={{ color: "black" }} />
                  </Button>
                </Box>
              </Container>
            </>
          )}
        </Box>
      </Box>
      <Container
        maxWidth={false}
        sx={{
          display: isSmallScreen ? "block" : "flex",
          backgroundRepeat: "no-repeat",
          backgroundSize: isSmallScreen ? " 473.89px, 138.9px" : "cover",
          justifyContent: "space-around",
          mt: 4,
          backgroundImage: 'url("../Assets/transparent.png")',
          height: "30rem",
          backgroundPositionY: "bottom",
        }}
      >
        <Box
          sx={{
            width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "30%",
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          <Typography variant="h5">Customer Reviews</Typography>
          <Box sx={{ marginTop: "30px" }}>
            <Box>
              {filteredReview?.length > 0 ? (
                <Box sx={{ marginTop: "30px" }}>
                  <Box sx={{ alignItems: "center" }}>
                    {renderStars(averageRating)}
                    <Typography variant="h6">
                      {filteredReview?.length} Reviews
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ marginTop: "30px" }}>
                  {[...Array(5)].map((_, index) => (
                    <StarIcon
                      key={`star-static-${index}`}
                      sx={{
                        color: "#E0E0E0",
                        backgroundColor: "secondary.main",
                        margin: "2px",
                        borderRadius: "50%",
                      }}
                    />
                  ))}
                  <Typography variant="h6" sx={{ marginLeft: "5px" }}>
                    (No reviews yet)
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Button
            sx={{
              width: isSmallScreen ? "100%" : "70%",
              color: "#ffffff",
              backgroundColor: "secondary.main",
              "&:hover": {
                backgroundColor: "secondary.main",
              },
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
              marginTop: "70px",
            }}
            onClick={handleClickOpen}
          >
            Write a review
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Write a Review</DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "400px",
                }}
              >
                <Rating
                  name="rating"
                  value={newReview.rating}
                  onChange={handleRatingChange}
                />
                <TextField
                  name="description"
                  label="Review"
                  multiline
                  rows={4}
                  value={newReview?.description}
                  onChange={handleReviewChange}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={handleSubmitReview}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Box
          sx={{
            width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "30%",
          }}
        >
          <Box
            sx={{
              maxWidth: "100%",
              maxHeight: isSmallScreen ? "150px" : 300,
              overflowY: "auto",
            }}
          >
            {filteredReview?.map((review) => (
              <Box
                key={review.id}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  padding: "10px",
                  marginTop: "10px",
                  width: isSmallScreen ? "auto" : "auto",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        width: "100%",
                        color: "#000",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "start",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          width: "100%",
                        }}
                      >
                        <AccountCircleIcon sx={{ fontSize: "25px" }} />
                        <Typography> {review.name}</Typography>
                      </Box>
                      <Typography
                        variant="subtitle"
                        sx={{
                          width: "100%",
                          wordBreak: "break-all",
                          textAlign: "left",
                        }}
                      >
                        {review.description}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {StarRating(review.rating)}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Products;
