import React, { useEffect } from "react";
import { Container, Typography, List, ListItem } from "@mui/material";

const RefundandCancellation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="lg" sx={{ paddingY: 10 }}>
      <Typography variant="h4" textAlign={"center"}>
        Refund and Cancellation Policy
      </Typography>
      <Typography variant="h6">Refund Policy:</Typography>
      <List>
        <ListItem>
          We strive to ensure your complete satisfaction with every purchase
          made from Milletheaven. If you are not entirely satisfied with your
          purchase, you may return it within 5 days of purchase for a full refund (except courier charges), provided the product is unused and in its original packaging.
        </ListItem>
        <ListItem>
          Refunds will be processed within 7-10 business days after receipt of the returned product. Shipping and handling fees are non-refundable.
        </ListItem>
        <ListItem>
          To request a refund, please contact our customer service team within 2
          days of receiving your order. Provide your order number and reason for
          the return. We will guide you through the process.
        </ListItem>
      </List>
      <Typography variant="h6">Cancellation Policy:</Typography>
      <List>
        <ListItem>
          Orders can be canceled without any penalty before they are shipped.
          Once the order has been shipped, it cannot be canceled.
        </ListItem>
        <ListItem>
          To cancel an order, please contact our customer service team as soon
          as possible with your order number.
        </ListItem>
        <ListItem>
          If the order has already been shipped, you will need to initiate a
          return once you receive the package.
        </ListItem>
      </List>
      <Typography variant="h6">Exceptions:</Typography>
      <List>
        <ListItem>
          Customized or personalized items may not be eligible for returns or
          refunds unless they arrive damaged or defective.
        </ListItem>
        <ListItem>
          Perishable goods such as food items may not be eligible for returns
          unless they are received in a damaged or defective condition.
        </ListItem>
      </List>
      <Typography variant="h6">Damaged or Defective Items:</Typography>
      <List>
        <ListItem>
          If you receive a damaged or defective item, please contact us
          immediately with photos of the product and packaging. We will arrange
          for a replacement or refund as applicable.
        </ListItem>
      </List>
      <Typography variant="h6">Contact Us:</Typography>
      <Typography variant="body1">
        If you have any questions about our refund and cancellation policy,
        please contact us!
      </Typography>
    </Container>
  );
};

export default RefundandCancellation;
