
import "./App.css";
import PageRouting from "./routes/PageRouting";
import { createTheme, ThemeProvider } from "@mui/material";
import Footer from './components/Footer';
import Navbar from "./components/Navbar";


const theme = createTheme({
  palette: {
    success: {
      main: "#4BCE97",
      background: "#EDFAF5",
    },
    primary: {
      main: "#FDB960",
      background: "#F5DDC2",
    },
    secondary: {
      main: "#F16C6C",
      background: "#F5DDC2",
    },
    error: {
      main: "#FF0000",
    },
    default: {
      main: "#445371",
      background: "#44537126",
    },
    neutral_light: {
      background: "#000000",
      main: "#FFFFFF",
    },
    neutral_dark: {
      background: "#FFFFFF",
      main: "#000000",
    },
    accent1: {
      background: "#F5DDC2",
      text: "#FFFFFF",
    },
    accent2: {
      background: "#F16C6C",
      main: "#FFFFFF",
    },
    accent2_rvt: {
      main: "#F16C6C",
      background: "#FFFFFF",
    },
    btn_acc_1: {
      background: "#F16C6C",
      main: "#FFFFFF",
    },
    btn_acc_2: {
      background: "#FDB960",
      main: "#000000",
    },
  },
  typography: {
    fontFamily: "Josefin Sans, sans-serif",
    fontSize: "14px",
    fontWeightBold: 700,
    h1: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 400,
      fontSize: "3.438rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 400,
      fontSize: "2.188rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    p: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    button: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme.bgColors = {
  bgprimary: {
    background: "#FDB960",
  },
  bgsecondary: {
    background: "#F16C6C",
    color: "#FFFFFF",
  },
  bgthird: {
    background: "#F5DDC2",
  },
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <PageRouting />
      <Footer />
    </ThemeProvider>
  );
}

export default App;