
import { Outlet, useNavigate } from "react-router";
import { Box, ListItem, Paper, Typography, } from "@mui/material";
import { FavoriteBorder, Logout, ShoppingCart } from "@mui/icons-material";
import useResponsive from "../../../hooks/useResponsive";
import { Link } from "react-router-dom";

const Profile = ({ handleLogout }) => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const navigate = useNavigate();
  function removeMultipleKeys(keysToRemove) {
    keysToRemove.forEach((key) => {
      if (localStorage.getItem(key) !== null) {
        localStorage.removeItem(key);
      } else {
        console.log(`Key ${key} does not exist in localStorage.`);
      }
    });
  }

  const LogoutFun = async () => {
    await handleLogout();
    navigate("/");
    const keysToRemove = ["userprofile", "allProducts", "allreviews"];
    removeMultipleKeys(keysToRemove);
    window.location.reload();
  };

  return (
    <Box>
      <Box
        sx={{
          marginY: isSmallScreen ? "20px" : isMediumScreen ? "40px" : "50px",
          display: isSmallScreen ? "block" : isMediumScreen ? "block" : "flex",
          justifyContent: "space-evenly",
          padding: isSmallScreen ? "20px" : isMediumScreen ? "40px" : "0px",
        }}
      >
        <Box
          sx={{
            width: isSmallScreen ? "100%" : isMediumScreen ? "100%" : "15%",
          }}
        >
          {/* <Typography variant='h4'>Navigation</Typography> */}
          <Paper elevation={12}>
            <Box
              sx={{
                border: "1px solid rgba(230, 230, 230, 1)",
                borderRadius: "5px",
                color: "rgba(102, 102, 102, 1)",
                lineHeight: "45px",
                padding: "10px",
                marginTop: "25px",
              }}
            >
              <ListItem sx={{ paddingX: "0px" }}>
                <Link
                  to="orderHistory"
                  color="inherit"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {" "}
                    <FavoriteBorder
                      className="icons"
                      style={{ marginBottom: "2px" }}
                    />
                    <span
                      style={{ marginLeft: "5px" }}
                      className="profile-orderlist "
                    >
                      My Orders
                    </span>
                  </Typography>
                </Link>
              </ListItem>
              <ListItem sx={{ paddingX: "0px" }}>
                <Link
                  to="cart"
                  color="inherit"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {" "}
                    <ShoppingCart
                      className="icons"
                      style={{ marginBottom: "2px" }}
                    />
                    <span
                      style={{ marginLeft: "5px" }}
                      className="profile-orderlist "
                    >
                      Shopping Cart
                    </span>
                  </Typography>
                </Link>
              </ListItem>
              <ListItem sx={{ paddingX: "0px", cursor: "pointer" }}>
                <Link
                  color="inherit"
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={LogoutFun}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {" "}
                    <Logout className="icons" style={{ marginBottom: "2px" }} />
                    <span
                      style={{ marginLeft: "5px" }}
                      className="profile-orderlist "
                    >
                      Log-out
                    </span>
                  </Typography>
                </Link>
              </ListItem>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{ width: isSmallScreen ? "100%" : "75%", marginY: isSmallScreen ? "20px" : isMediumScreen ? "40px" : "30px", }}
        >
          <Paper elevation={12}><Outlet></Outlet></Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
