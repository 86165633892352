import { useContext, useEffect } from 'react';
import { Context } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ userelement, adminelement }) => {
    const { isUserLoggedIn, isAdminLoggedIn } = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isUserLoggedIn && !isAdminLoggedIn) {
            navigate('/login');
        }
    }, [isUserLoggedIn, isAdminLoggedIn, navigate]);

    if (isAdminLoggedIn) {
        return adminelement;
    }

    if (isUserLoggedIn) {
        return userelement;
    }

    return null;
};

export default ProtectedRoute;
