import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate} from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate('/');
  };

  const style={
    pagenotfound: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      },
      
      notfoundimage:{
        width:" 30%",
        height: "auto",
        marginBottom: "20px",
      },
      
      button:{
        marginTop: "20px",
        padding:" 10px 20px",
        fontSize: "16px",
        cursor: "pointer",
      }
      
  }

  return (
    <Box sx={style.pagenotfound}>
     <Box sx={{marginBottom:5}}>
     <img sx={style.notfoundimage} src="../Assets/4O4.png" alt="Page Not Found" />
      <Typography variant='h2' component={'div'}>Page Not Found</Typography>
      <Button  variant="contained" sx={style.button} onClick={handleBackHome}>Back to Home</Button>
     </Box>
    </Box>
  );
};

export default PageNotFound;
