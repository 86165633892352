import React, { createContext, useEffect, useState } from 'react';
import apiUrl, { products_data } from '../config';
import axios from 'axios';

const Context = createContext();

const UserContext = ({ children }) => {
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };


  const [userToken, setUserToken] = useState(getCookie('userToken'));
  const [adminToken, setAdminToken] = useState(getCookie('AdminToken'));
  const [myprofile, setMyProfile] = useState(JSON.parse(localStorage.getItem('userprofile')));
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(adminToken);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(userToken);
  const [products, setProducts] = useState(JSON.parse(localStorage.getItem('allProducts')));
  const [selectprod, setSelectProd] = useState(0);

  const fetchProducts = async () => {
    try {
      const res = await axios.get(apiUrl + 'getProduct');
      localStorage.setItem('allProducts', JSON.stringify(res.data.AllProducts))
      setProducts(JSON.parse(localStorage.getItem('allProducts')));
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const UserProfile = async () => {
    try {
      const res = await axios.get(apiUrl + `GetUser`, {
        headers: {
          "token": userToken
        },
      });
      localStorage.setItem('userprofile', JSON.stringify(res.data.user))
      setMyProfile(JSON.parse(localStorage.getItem('userprofile')));
    } catch (err) {
      console.log("Error fetching user profile:", err);
    }
  };


  useEffect(() => {
    if (userToken) {
      UserProfile();
    }
  }, [userToken]);

  useEffect(() => { fetchProducts() }, [])

  const contextValue = { isAdminLoggedIn, setIsAdminLoggedIn, setAdminToken, isUserLoggedIn, setIsUserLoggedIn, userToken, adminToken, myprofile, UserProfile, products_data, products, fetchProducts, setUserToken,selectprod, setSelectProd };
  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  );
};


export { Context, UserContext };
