import { Box, Container, Typography } from "@mui/material";
import React from "react";
import useResponsive from "../../hooks/useResponsive";

const HomeAboutSection = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();

  return (
    <>
      <Box sx={{ backgroundColor: "primary.background", paddingY: "25px" }}>
        <Container>
          <Box textAlign={"center"}>
            <Typography
              variant={isSmallScreen ? "h4" : isMediumScreen ? "h5" : "h2"}
              marginY={2}
              sx={{
                background: "secondary.main",
                background: "linear-gradient(0deg, rgba(255,84,72,1) 53%, rgba(254,161,78,1) 87%)",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
              }}
            >
              what is millet heaven..?
            </Typography>

            <Typography
              variant={isSmallScreen ? "p" : isMediumScreen ? "h5" : "h4"}
              paddingX={0}
              sx={{ color: "#554200" }}
            >
              Welcome to Millet Heaven, where wholesome nutrition meets
              delectable taste. Our brand is dedicated to crafting products that
              harmoniously blend the finest millets and an array of premium dry
              fruits. The result? A deliciously unique offering that not only
              tantalizes your taste buds but also nourishes your body with every
              bite. Millet Heaven stands out by embracing the ancient goodness
              of millets, known for their rich nutrient profile and numerous
              health benefits. By pairing these powerhouse grains with a
              selection of handpicked dry fruits, we create an unparalleled
              combination that supports a balanced diet and a vibrant lifestyle.
            </Typography>
          </Box>
        </Container>
      </Box>
      <WaveSVG />
    </>
  );
};

export default HomeAboutSection;

export const WaveSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 265">
    <path
      fill="#F5DDC2"
      fill-opacity="1"
      d="M0,128L120,160C240,192,480,256,720,256C960,256,1200,192,1320,160L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
    ></path>
  </svg>
);
