import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../../config';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const { token } = useParams();

    const resetPassword = async () => {
        try {
            const response = await axios.post(`${apiUrl}RestPassword`, { password, token });
            if (response.data.success) {
                setSuccess("Password reset successfully.");
                setError("");
            } else {
                setError("Password reset failed. Please try again.");
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message === "JsonWebTokenError") {
                setError("The token has expired or is invalid.");
            } else {
                setError("An unexpected error occurred. Please try again.");
            }
            setSuccess("");
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>Reset Password</Typography>
            <Typography variant="body1" gutterBottom>Enter your new password below to reset it.</Typography>

            <TextField
                type="password" // Use password input type
                label="New Password"
                placeholder="Enter Your New Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                fullWidth
                margin="normal"
            />

            {error && <Typography variant='h6' sx={{ color: "red" }}>{error}</Typography>}
            {success && <Typography variant='h6' sx={{ color: "green" }}>{success}</Typography>}

            <Button
                variant="contained"
                color="primary"
                onClick={resetPassword}
                sx={{ marginTop: 2 }}
            >
                Update
            </Button>
        </Box>
    );
};

export default ResetPassword;
