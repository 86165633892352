import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Box,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

const ProductCard = ({ product, handleEdit, handleDelete }) => {
  const [selectedWeight, setSelectedWeight] = useState(0);

  const handleWeightChange = (event) => {
    setSelectedWeight(event.target.value);
  };

  
  return (
    <Card
      sx={{
        maxWidth: 800,
        margin: 4,
        boxShadow: 3,
        display: "flex",
        backgroundColor:"primary.background",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
     <Box sx={{py:4,px:2}}>
     <CardMedia
        component="img"
        sx={{
          width: { xs: "100%", md: 200 },
          objectFit: "cover",
        }}
        image={product.image} // Replace with actual image path
        alt="Product Image"
      />
     </Box>
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
       <Box display={"flex"} justifyContent={"space-between"} borderRadius={"5px"}>
       <Typography variant="h5" component="div" sx={{ color: "#333" }}>
          {product.title}
        </Typography>
        <Box sx={{width:"fit-content", padding:"5px" , backgroundColor:"#fff", borderRadius:"5px", alignItems:"end"}}>
        <Typography >
          {product.instock?"Available Now":"Out of Stock"}
        </Typography>
        </Box>
       </Box>
        <Box
          display={"flex"}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Box sx={{ width: { xs:"100%", md:"50%"} }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Weight</InputLabel>
              <Select
                value={selectedWeight}
                onChange={handleWeightChange}
                label="Weight"
              >
                {JSON.parse(product?.quantity)?.map((val, index) => (
                  
                  <MenuItem key={index} value={index}>
                    {val.weight} grams
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body2">
              Original Price: {JSON.parse(product?.quantity)[selectedWeight]?.originalPrice}{" "}
            </Typography>
            <Typography variant="body2">
              Discount Price:
              {JSON.parse(product?.quantity)[selectedWeight]?.discountPrice}{" "}
            </Typography>
            <Box>
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                }}
              >
                {product.bulletins.split(", ").map((item) => (
                  <>
                    <ListItem sx={{ display: "list-item" }}>{item}</ListItem>
                  </>
                ))}
              </List>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ borderColor: "neutral_dark.main", marginX: 2 }}
          />
          <Box sx={{ width: { xs:"100%", md:"50%"} }}>
          <Typography
              variant="h6"
              component={'div'}
              sx={{ marginTop: 2, marginBottom: 2, color: "#666" }}
            >
              Nutrition Information:</Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: 2, marginBottom: 2, color: "#666" }}
            >
             Proteins, Potassium, Phosphorous, Sodium,
              Iron, Fiber, Calcium, Zinc, Folic acid, Thiamin, Copper,
              Pyridoxamine, Riboflavin, Magnesium, Selenium, Cobalt, Molybdenum,
              Nickel
            </Typography>
            <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => handleEdit(product)}
                // sx={{ ml: "auto" }}
              >
                Edit
              </Button>
              <Button variant="contained" color="error" size="small" onClick={() => handleDelete(product.id)}>
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
