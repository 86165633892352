import React, { useEffect } from 'react'
import HomeAboutSection from './Home/HomeAboutSection'
import HomeFeaturesSection from './Home/HomeFeaturesSection'
import HomeProductsSection from './Home/HomeProductsSection'
import HomeHeaderSection from './Home/HomeHeaderSection'
import HomeFooterSection from './Home/HomeFooterSection'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <>
      <HomeHeaderSection/>
      <HomeAboutSection/>
      <HomeFeaturesSection/>
      <HomeProductsSection/>
      <HomeFooterSection/>
      </>
    )
}

export default Home