import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
    Container, Grid, TextField, Select, MenuItem, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Pagination, Typography, FormControl, InputLabel,
} from "@mui/material";
import apiUrl from "../../config.js";
import { Context } from "../../context/UserContext.js";

const OrderList = () => {
    const { products } = useContext(Context)
    const [ordersList, setOrdersList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedDate, setSelectedDate] = useState("");
    const [orderId, setOrderId] = useState("");

    useEffect(() => {
        getAllOrders(selectedDate, orderId);
    }, [selectedDate, orderId]);

    const getAllOrders = async (date,) => {
        try {
            let url = apiUrl + "allorders";
            let config = {
                headers: { "Content-Type": "application/json", },
            };
            if (date) {
                url += `?date=${date}`;
            }
            const res = await axios.get(url, config);
            setOrdersList(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const reversedOrderlist = useMemo(() => ordersList.slice().reverse(), [ordersList]);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = reversedOrderlist.filter((order) => {
        if (!selectedDate && !orderId) return true;
        const orderDate = new Date(order.createdAt).toLocaleDateString("en-IN", { year: "numeric", month: "2-digit", day: "2-digit", })
            .split("/").reverse().join("-");
        const orderIdLowerCase = order.orderId.toLowerCase();
        const searchOrderIdLowerCase = orderId.toLowerCase();
        return ((!selectedDate || orderDate === selectedDate) && (!orderId || orderIdLowerCase.includes(searchOrderIdLowerCase)));
    })
        .slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(reversedOrderlist.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleOrderIdChange = (e) => {
        setOrderId(e.target.value);
    };

    const handleReset = () => {
        setSelectedDate("");
        setOrderId("");
        setOrdersList(currentItems);
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h5" gutterBottom>All Orders</Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        label="Search by Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        label="Search by Order ID"
                        fullWidth
                        value={orderId}
                        onChange={handleOrderIdChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Items Per Page</InputLabel>
                        <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            label="Items Per Page"
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button variant="contained" color="primary" onClick={handleReset}>Reset</Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No.</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Order Details</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.length === 0 && (
                            <TableRow><TableCell colSpan={8} align="center">No orders were placed.</TableCell></TableRow>
                        )}
                        {currentItems.map((order, index) => {
                            const orderIndex = indexOfFirstItem + index;
                            const { totalAmount, createdAt, user } = order;
                            const createdAtDate = new Date(createdAt);
                            const formattedCreatedAt = createdAtDate.toLocaleDateString("en-IN", { day: "2-digit", month: "2-digit", year: "numeric" });

                            return (
                                <TableRow key={orderIndex}>
                                    <TableCell>{orderIndex + 1}</TableCell>
                                    <TableCell>{formattedCreatedAt}</TableCell>
                                    <TableCell>{order?.orderId}</TableCell>
                                    <TableCell>{user?.name}</TableCell>
                                    <TableCell>{order.address.mobile || user?.mobile}</TableCell>
                                    <TableCell>
                                        {JSON.parse(order.products)?.map((items, index) => {
                                            const productDetails = products.find((pickle) => parseInt(pickle.id) === parseInt(items.productId));

                                            return (
                                                <div key={index}>
                                                    {productDetails && (
                                                        <div>
                                                            <Typography>{productDetails.title} ({items.size})</Typography>
                                                            <Typography>Quantity: {items.quantity}</Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </TableCell>
                                    <TableCell>₹{totalAmount}</TableCell>
                                    <TableCell>
                                    {JSON.parse(order.address).streetAddress}, {JSON.parse(order.address).city},{" "}
                                        {JSON.parse(order.address).district}, {JSON.parse(order.address).state},{" "}
                                        {JSON.parse(order.address).country}, {JSON.parse(order.address).pincode}
                                        <Typography>Phone Number: {order.address.mobile || user?.mobile}</Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={pageNumbers.length}
                page={currentPage}
                onChange={(e, page) => paginate(page)}
                color="primary"
                style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
            />
        </Container>
    );
};

export default OrderList;
