import React, { useContext, useState } from 'react';
import { Box, Button, InputLabel, TextField, Typography, CircularProgress } from '@mui/material';
import axios from "axios";
import apiUrl from '../../config';
import { Context } from '../../context/UserContext';
import useResponsive from '../../hooks/useResponsive';
import { Link, useNavigate } from 'react-router-dom';

const setCookie = (name, value, minutes, secure = false) => {
  const expirationDate = new Date(Date.now() + minutes * 60 * 1000);
  let cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/; SameSite=Strict`;
  if (secure) {
    cookieString += '; Secure';
  }
  document.cookie = cookieString;
};

const Login = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const { setIsAdminLoggedIn, setIsUserLoggedIn, UserProfile, setAdminToken, setUserToken } = useContext(Context);
  const [userDetails, setUserDetails] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) && email.endsWith('@gmail.com');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const PostDetails = async () => {
    if (!userDetails.email || !userDetails.password) {
      setError(true);
      return;
    }
    if (!isValidEmail(userDetails.email)) {
      setError(true);
      return;
    }
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(`${apiUrl}LoginUser`, userDetails);
      if (response.data && typeof response.data === 'object') {
        const { token, success, role } = response.data;
        if (success === true && role === "User") {
          // window.location.reload()
          setCookie('userToken', token, 60, window.location.protocol === 'https:');
          setUserToken(token)
          setIsUserLoggedIn(true);
          setError('');
          navigate('/');
        } else if (success === true && role === "Admin") {
          setCookie('AdminToken', token, 60, window.location.protocol === 'https:');
          setIsAdminLoggedIn(true);
          navigate('/admin/');
          setAdminToken(token)
          setError('');
        }
      }
    } catch (error) {
      setError("Login failed. Please check your email and password.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh", backgroundColor: "#f5f5f5" }}>
      <Box style={{ width: isSmallScreen ? "100%" : isMediumScreen ? "100%" : "25%", padding: 24, backgroundColor: "white", borderRadius: 8, boxShadow: '0px 0px 15px rgba(0,0,0,0.2)' }}>
        <Typography variant="h6" gutterBottom>Login</Typography>
        <InputLabel>Email</InputLabel>
        <TextField
          size='small'
          label="Enter your Email"
          variant="outlined"
          margin="normal"
          fullWidth
          name='email'
          value={userDetails.email}
          onChange={handleChange}
          error={!!error && (!userDetails.email || !isValidEmail(userDetails.email))}
        />
        <InputLabel>Password</InputLabel>
        <TextField
          size='small'
          label="Enter your Password"
          variant="outlined"
          margin="normal"
          fullWidth
          type="password"
          name='password'
          value={userDetails.password}
          onChange={handleChange}
          error={!!error && !userDetails.password}
        />
        {error && <Typography color="error" variant="body2" style={{ marginTop: 8 }}>{error}</Typography>}
        <Box style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
          <Button
            variant='contained'
            onClick={PostDetails}
            disabled={loading}
            fullWidth
            sx={{
              backgroundColor: "primary.main",
              '&:hover': {
                backgroundColor: "primary.main"
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Login"}
          </Button>
        </Box>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={3}>
        <Link to="/forgetpassword" style={{textDecoration:"none", textTransform:"none"}}>
        <Button   style={{ display: 'block', textAlign: "end",  color: "primary.main" }}>ForgotPassword</Button>

        </Link>
      <Link to="/register" style={{ display: 'block', textAlign: "end", color:"#FDB960", textDecoration:"none"}}>
      <Button sx={{backgroundColor:"btn_acc_1.background", color:"btn_acc_1.main" ,  "&:hover": {
                  backgroundColor: "secondary.main",
                },}}> Register</Button>
      
     </Link>
      </Box>
      </Box>
    </Box>
  );
};

export default Login;
