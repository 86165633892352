import React, { useEffect } from "react";
import { Container, Typography, Link, List, ListItem } from "@mui/material";

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <Container maxWidth="lg" sx={{paddingY:10,}}>
            <Typography variant="h4"  textAlign={"center"} >
                Terms and Conditions
            </Typography>
            <Typography variant="h6" gutterBottom >
                Introduction
            </Typography>
            <Typography variant="body1" paragraph >
                Welcome to MilletsHeaven. These terms and conditions outline the rules
                and regulations for the use of our website, located at{" "}
                <Link href="http://www.MilletsHeaven.com">www.MilletsHeaven.com</Link>.
            </Typography>
            <Typography variant="body1" paragraph >
                By accessing this website, we assume you accept these terms and
                conditions. Do not continue to use MilletsHeaven if you do not agree to
                all the terms and conditions stated on this page.
            </Typography>
            <Typography variant="h6" gutterBottom >
                Accounts and Registration
            </Typography>
            <Typography variant="body1" paragraph >
                To access certain features of the website, you may be required to create
                an account. You agree to provide accurate, current, and complete
                information during the registration process and to update such
                information to keep it accurate, current, and complete.
            </Typography>
            <Typography variant="body1" paragraph >
                You are responsible for maintaining the confidentiality of your account
                password and for all activities that occur under your account. You agree
                to notify us immediately of any unauthorized use of your account.
            </Typography>
            <Typography variant="h6" gutterBottom >
                Orders and Payment
            </Typography>
            <Typography variant="body1" paragraph >
                By placing an order through our website, you are offering to purchase a
                product on and subject to the following terms and conditions:
            </Typography>
            <List >
                <ListItem>
                    All orders are subject to availability and confirmation of the order
                    price.
                </ListItem>
                <ListItem>Orders will be acceptable by 24/7 365 days</ListItem>
                <ListItem>
                    Delivery times may vary according to availability and any guarantees
                    or representations made as to delivery times are subject to any delays
                    resulting from postal delays or force majeure for which we will not be
                    responsible.
                </ListItem>
                <ListItem>
                    In order to contract with MilletsHeaven, you must be over 18 years of
                    age and possess a valid credit or debit card issued by a bank
                    acceptable to us. MilletsHeaven retains the right to refuse any
                    request made by you.
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom >
                Pricing and Availability
            </Typography>
            <Typography variant="body1" paragraph >
                While we try to ensure that all details, descriptions, and prices
                appearing on the website are accurate, errors may occur. If we discover
                an error in the price of any goods which you have ordered, we will
                inform you of this as soon as possible and give you the option of
                reconfirming your order at the correct price or canceling it. If we are
                unable to contact you, we will treat the order as canceled.
            </Typography>
            <Typography variant="h6" gutterBottom >
                Delivery
            </Typography>
            <Typography variant="body1" paragraph sx={{ marginBottom: 2 }}>
                Delivery costs will be charged in addition to the purchase price; such
                additional charges are clearly displayed where applicable and included
                in the 'Total Cost'.
            </Typography>
        </Container>
    );
};

export default TermsAndConditions;
