import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import StarIcon from "@mui/icons-material/Star";
import useResponsive from "../../hooks/useResponsive";
import apiUrl, { features, products_data } from "../../config";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  Rating,
  Typography,
} from "@mui/material";
import { Autoplay, Pagination } from "swiper/modules";
import CarousalPagination from "./CarousalPagination";
import { Context } from "../../context/UserContext";
import axios from "axios";

const HomeHeaderSection = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [reviews, setReviews] = useState(
    JSON.parse(localStorage.getItem("allreviews"))
  );
  const swiperRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { products , setSelectProd} = useContext(Context);
  const fetchReviews = async () => {
    try {
      const response = await axios.get(apiUrl + "getreviews");
      localStorage.setItem("allreviews", JSON.stringify(response.data));
      setReviews(JSON.parse(localStorage.getItem("allreviews")));
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };
  useEffect(() => {
    fetchReviews();
  }, []);
  const prevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  const nextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };
  const prodrating = (id) => {
    const filteredReview = reviews?.filter(
      (review) => parseInt(review.productId) === parseInt(id)
    );
    const averageRating =
      filteredReview?.length > 0
        ? filteredReview.reduce((sum, review) => sum + review.rating, 0) /
          filteredReview.length
        : 0;

    return averageRating;
  };
  const handleselectprod=(selectIndex)=>{
    setSelectProd(selectIndex)
  }
  return (
    <>
      <Box
        sx={{
          backgroundColor: "primary.background",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Container>
          <Swiper
            spaceBetween={30}
            loop={true}
            modules={[Autoplay, Pagination]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
          >
            {products &&
              products?.map((prodItems, index) => (
                <SwiperSlide>
                  <Box
                    mb={2}
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: isSmallScreen
                        ? "row"
                        : isMediumScreen
                        ? "column"
                        : "row",
                      justifyContent: "space-evenly",
                      marginY: isSmallScreen ? "10px" : "60px",
                      padding: isSmallScreen ? "12px 0px" : "55px 15px",
                      borderRadius: "15px",
                      color: "accent2.main",
                      backgroundColor: "accent2.background",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: isSmallScreen
                          ? "flex"
                          : isMediumScreen
                          ? "flex"
                          : "flex",
                        justifyContent: "space-around",
                        width: isSmallScreen ? "70%" : "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: isSmallScreen ? "170px" : "280px",
                          marginX: isSmallScreen ? 1 : 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img src={prodItems.image} width={"100%"} />
                      </Box>

                      <Box>
                        <Typography
                          variant={isSmallScreen ? "body2" : "h4"}
                          sx={{ lineHeight: isSmallScreen ? "1rem" : "2.5rem" }}
                        >
                          {prodItems.title}
                        </Typography>
                        <Box
                          sx={{
                            display: isSmallScreen ? "none" : "flex",

                            alignItems: "center",

                            backgroundColor: "#ffffff",

                            width: isSmallScreen ? "28px" : "70px",

                            padding: "5px",

                            borderRadius: "5px",
                          }}
                        >
                          <Typography
                            variant={isSmallScreen ? "body1" : "h6"}
                            sx={{ color: "#000", marginLeft: "5px" }}
                          >
                            {prodrating(prodItems.id)}
                          </Typography>

                          <StarIcon
                            sx={{
                              marginBottom: "5px",
                              color: "#FFD700",
                              fontSize: "20px",
                              marginLeft: "5px",
                            }}
                          />
                        </Box>

                        <Typography
                          mt={1}
                          sx={{
                            fontSize: "8.4px",
                          }}
                        >
                          {prodItems.subtitles}
                        </Typography>
                        <Box>
                          <List
                            sx={{
                              listStyleType: "disc",
                              listStylePosition: "inside",
                              fontSize: isSmallScreen ? "6.4px" : "body1",
                            }}
                          >
                            {prodItems.bulletins.split(", ").map((item) => (
                              <>
                                <ListItem
                                  sx={{
                                    display: "list-item",
                                    paddingTop: "0px",
                                  }}
                                >
                                  {item}
                                </ListItem>
                              </>
                            ))}
                          </List>
                        </Box>
                        <Box>
                          <Typography
                            variant={isSmallScreen ? "body1" : "h6"}
                            sx={{
                              color: "neutral_dark.main",
                              marginY: "5px",
                              fontSize: isSmallScreen ? "8.18px" : "23px",
                            }}
                          >
                            <s>
                              {" "}
                              &#x20b9;
                              {Array.isArray(prodItems.quantity)
                                ? prodItems.quantity[0]?.originalPrice
                                : JSON.parse(prodItems.quantity)[0]
                                    ?.originalPrice}
                              {".00"}
                            </s>{" "}
                            &#x20b9;
                            {Array.isArray(prodItems.quantity)
                              ? prodItems.quantity[0]?.discountPrice
                              : JSON.parse(prodItems.quantity)[0]
                                  ?.discountPrice}
                            {".00"}
                            {".00"}
                          </Typography>
                          <Button
                            component={Link}
                            href="/#/products"
                            onClick={()=>handleselectprod(index)}

                            sx={{
                              backgroundColor: "btn_acc_2.background",
                              color: "btn_acc_2.main",
                              // padding: "11px 64px",
                              borderRadius: "25px",
                              alignContent: "center",
                              marginTop: isSmallScreen ? "5px" : "25px",
                              width: isSmallScreen ? "74px" : "210px",
                              fontSize: isSmallScreen ? "6px" : "16px",

                              "&:hover": {
                                backgroundColor: "btn_acc_2.background",
                                color: "btn_acc_2.main",
                              },
                            }}
                          >
                            {" "}
                            Buy Now
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ borderColor: "neutral_light.main" }}
                    />
                    <Box>
                      <List
                        sx={{
                          display: isSmallScreen
                            ? "block"
                            : isMediumScreen
                            ? "flex"
                            : "block",
                        }}
                      >
                        {features.map((item) => (
                          <>
                            <ListItem
                              sx={{
                                paddingY: isSmallScreen ? "5px" : "",

                                paddingX: isSmallScreen ? "0px" : "",
                              }}
                            >
                              {" "}
                              <Box
                                sx={{
                                  display: isSmallScreen
                                    ? "flex"
                                    : isMediumScreen
                                    ? "block"
                                    : "flex",

                                  paddingY: isSmallScreen ? "0px" : "",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: isSmallScreen
                                    ? "100%"
                                    : isMediumScreen
                                    ? "100%"
                                    : "50%",
                                }}
                              >
                                <Box
                                  sx={{
                                    marginLeft: isSmallScreen ? "15px" : "",
                                    marginRight: isSmallScreen ? "5px" : "15px",
                                    width: isSmallScreen
                                      ? "10%"
                                      : "fit-content",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: isSmallScreen
                                        ? "15px"
                                        : "fit-content",
                                      height: isSmallScreen ? "15px" : "40.5px",
                                      padding: "1px !important",
                                      border: " 1px solid white !important",
                                      borderRadius: "48% !important",
                                      background: "white !important",
                                    }}
                                  >
                                    <img
                                      src={item.img_url}
                                      width={isSmallScreen ? "15px" : "42px"}
                                    />
                                  </Box>
                                </Box>{" "}
                                <Box
                                  sx={{
                                    marginX: isSmallScreen ? "8px" : "25px",
                                    fontSize: isSmallScreen ? "6.4px" : "body1",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.title}
                                </Box>
                              </Box>
                            </ListItem>
                          </>
                        ))}
                      </List>
                      <Box
                        width={isSmallScreen ? "100%" : 480}
                        sx={{ marginX: isSmallScreen ? "8px" : "16px" }}
                      >
                        <Typography
                          mb={2}
                          variant="p"
                          sx={{
                            padding: "5px 5px",
                            display: "inline",
                            backgroundColor: "accent2_rvt.background",
                            color: "accent2_rvt.main",
                            fontSize: isSmallScreen ? "6.4px" : "body1",
                          }}
                        >
                          Nutrition Information
                        </Typography>

                        <Typography
                          sx={{
                            marginY: isSmallScreen ? "5px" : "5px",
                            fontSize: isSmallScreen ? "6.4px" : "body1",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            textOverflow: "ellipsis",
                          }}
                        >
                          {prodItems.ingredients}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            {/* <CarousalPagination/> */}
          </Swiper>
        </Container>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "25px",
            }}
          >
            <Button onClick={() => prevSlide()}>
              <ArrowBackIosIcon sx={{ color: "black", fontSize: "25px" }} />
            </Button>
            <Swiper
              loop={true}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                },
              }}
            >
              {products &&
                products.map((val, index) => (
                  <SwiperSlide key={index} sx={{ p: 2 }}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        height: 516,
                        p: 2,
                        boxShadow: 3,
                        backgroundColor: "primary.main",
                        borderRadius: "20px",
                        marginBottom: 2,
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "neutral_light.main",
                          padding: "10px 6px",
                          borderRadius: "15px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={val.image}
                          alt="Ragiwow 300g"
                          sx={{
                            width: "220px",
                            height: "220px",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          color: "neutral_light.main",
                        }}
                      >
                        <Typography
                          variant="h5"
                          noWrap={false}
                          component="div"
                          sx={{
                            textAlign: "center",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                          }}
                        >
                          {val.title}
                        </Typography>

                        <Box display={"flex"}>
                          <Typography
                            variant="h6"
                            color="text.primary"
                            sx={{ mt: 2, textDecoration: "line-through" }}
                          >
                            {val.quantity.originalPrice}
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            sx={{ mb: 2, mx: 1 }}
                          >
                            {val.quantity.discountPrice}
                          </Typography>
                        </Box>
                        <Box
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            mb: 2,

                            textAlign: "left",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 2,
                              textOverflow: "ellipsis",
                              textAlign: "left",
                            }}
                          >
                            {val.subtitles}
                          </Typography>

                          <Box display={"flex"} sx={{ mt: 1 }}>
                            <Typography
                              variant="h6"
                              color="text.primary"
                              sx={{ textDecoration: "line-through" }}
                            >
                              &#x20b9;
                              {Array.isArray(val.quantity)
                                ? val.quantity[0]?.originalPrice
                                : JSON.parse(val.quantity)[0]?.originalPrice}
                              {".00"}
                            </Typography>
                            <Typography
                              variant="h5"
                              color="text.primary"
                              sx={{ mb: 2, mx: 1 }}
                            >
                              &#x20b9;
                              {Array.isArray(val.quantity)
                                ? val.quantity[0]?.discountPrice
                                : JSON.parse(val.quantity)[0]?.discountPrice}
                              {".00"}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                      <CardActions
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "accent2.background",

                            color: "accent2.main",

                            "&:hover": {
                              backgroundColor: "accent2.background",
                            },
                          }}
                          href="/#/products"

                          onClick={()=>handleselectprod(index)}

                        >
                          Add to cart
                        </Button>
                      </CardActions>
                    </Card>
                  </SwiperSlide>
                ))}
            </Swiper>
            <Button onClick={() => nextSlide()}>
              <ArrowForwardIosIcon sx={{ color: "black", fontSize: "25px" }} />
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default HomeHeaderSection;
