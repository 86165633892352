import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import NoAddictions from "../../Assets/Group 305.png";
import NoSugar from "../../Assets/Group 306.png";
import NoSoya from "../../Assets/Group 307.png";
import NoGulten from "../../Assets/Group 308.png";
import NoColor from "../../Assets/Group 309.png";
import useResponsive from "../../hooks/useResponsive";
import Ellipse from "../../Assets/Ellipse.png";
const HomeFeaturesSection = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();

  const style = {
    card: {
      width: isSmallScreen?"20px":isMediumScreen?"80px":"138px",
      margin:"20px",
      display:isSmallScreen?"flex":"block",
      flexDirection:isSmallScreen?"column":"row",
      justifyContent:isSmallScreen?"center":"",
      alignItems:"center"
      
    },
  };

  return (
    <>
      <Box sx={{ backgroundImage: Ellipse }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "row" : "row",
            justifyContent: isSmallScreen
              ? "center"
              : isMediumScreen
              ? "center"
              : "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
            marginY: isSmallScreen?"-5px":"51px",
            marginBottom: isSmallScreen?"-40px":isMediumScreen?"10px":"-16px",
            gap:isSmallScreen?"0": "5",
          }}
        >
          <Box sx={style.card}>
            <Box>
              <img src={NoAddictions} width={isSmallScreen?"48px":"100%"} />
            </Box>
            <Box>
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                  fontSize:isSmallScreen?"10px":isMediumScreen?"24px":"35x",
                  background: "secondary.main",
                  color: "#FEA34E",
                  marginTop: isSmallScreen?0:5,
                }}
              >
                No <br />
                Addictions
              </Typography>
            </Box>
          </Box>
          <Box sx={style.card}>
            <Box>
              <img src={NoSugar}  width={isSmallScreen?"48px":"100%"}/>
            </Box>
            <Box>
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                    fontSize:isSmallScreen?"10px":isMediumScreen?"24px":"35x",
                  background: "secondary.main",
                  color: "#FEA34E",
                   marginTop: isSmallScreen?0:5,
                }}
              >
                No <br />
                Sugar
              </Typography>
            </Box>
          </Box>
          <Box sx={style.card}>
            <Box>
              <img src={NoSoya}  width={isSmallScreen?"48px":"100%"}/>
            </Box>
            <Box>
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                    fontSize:isSmallScreen?"10px":isMediumScreen?"24px":"35x",
                  background: "secondary.main",
                  color: "#FEA34E",
                   marginTop: isSmallScreen?0:5,
                }}
              >
                No <br />
                Soya
              </Typography>
            </Box>
          </Box>
          <Box sx={style.card}>
            <Box>
              <img src={NoGulten}  width={isSmallScreen?"48px":"100%"}/>
            </Box>
            <Box>
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                    fontSize:isSmallScreen?"10px":isMediumScreen?"24px":"35x",
                  background: "secondary.main",
                  color: "#FEA34E",
                   marginTop: isSmallScreen?0:5,
                }}
              >
                No <br />
                Gulten
              </Typography>
            </Box>
          </Box>
          <Box sx={style.card} >
            <Box>
              <img src={NoColor}  width={isSmallScreen?"48px":"100%"}/>
            </Box>
            <Box>
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                    fontSize:isSmallScreen?"10px":isMediumScreen?"24px":"35x",
                  background: "secondary.main",
                  color: "#FEA34E",
                   marginTop: isSmallScreen?0:5,
                }}
              >
                No <br />
                Color
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* <img
        src={Ellipse}
        style={{
          // position: 'absolute',
          clip: "rect(0px, 160px, 160px, 0px)",
          // Adjust as needed
        }}
      /> */}
      <WaveSVG/>
    </>
  );
};

export default HomeFeaturesSection;

export const WaveSVG = () => (
  // <svg
  //   width="100%"
  //   height="400"
  //   viewBox="0 0 1440 400"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <defs>
  //     <linearGradient id="greenGradient" x1="0%" y1="0%" x2="0%" y2="100%">
  //       <stop
  //         offset="0%"
  //         style={{ stopColor: "rgb(85,185,48)", stopOpacity: "1" }}
  //       />
  //       <stop
  //         offset="100%"
  //         style={{ stopColor: "rgb(143,204,102)", stopOacity: "1" }}
  //       />
  //     </linearGradient>
  //   </defs>
  //   <rect width="100%" height="100%" fill="white" />
  //   <g transform="rotate(180, 400, 200)">
  //     {/* <path d="M0,100 Q400,0 1440,100 L1440,400 L0,400 Z" fill="#f5e5cc" />  */}
  //     <path
  //       d="M0,200 Q400,100 1440,200 L1440,400 L0,400 Z"
  //       fill="url(#greenGradient)"
  //     />
  //     <path d="M0,300 Q400,200 1440,300 L1440,400 L0,400 Z" fill="white" />
  //       
  //   </g>
  // </svg>
  <svg viewBox="0 130 1920 190" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 128 Q 960 512 1920 128 L 1920 512 L 0 512 Z" fill="#f6dfc9"/>
</svg>
);
