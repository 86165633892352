import React, { useState } from 'react';
import { Box, Button, InputLabel, TextField, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import apiUrl from '../../config';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';

const Register = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive();

    const [userDetails, setUserDetails] = useState({ name: '', email: '', password: '', mobile: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile' && (!/^\d*$/.test(value) || value.length > 10)) {
            return;
        }

        setUserDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email) && email.endsWith('@gmail.com');
    };

    const postDetails = async () => {
        if (!userDetails.name || !userDetails.email || !userDetails.password || !userDetails.mobile) {
            setError(true);
            return;
        }

        if (userDetails.mobile.length !== 10) {
            setError(true);
            return;
        }

        if (!isValidEmail(userDetails.email)) {
            setError(true);
            return;
        }

        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${apiUrl}RegisterUser`, userDetails);
            if (response.data.success) {
                setLoading(false);
                navigate('/login');
            } else {
                setError(response.data.message || 'Registration failed.');
            }
        } catch (error) {
            setLoading(false);
            setError('Registration failed. Please check your details and try again.');
            console.error(error);
        }
    };

    return (
        <Box sx={{ display: isSmallScreen ? 'block' : 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
            <Box sx={{ width: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '25%', padding: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3 }}>
                <Typography variant="h6" gutterBottom>Register</Typography>
                <InputLabel>Name</InputLabel>
                <TextField
                    size="small"
                    label="Enter your Name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="name"
                    value={userDetails.name}
                    onChange={handleChange}
                    error={!!error && !userDetails.name}
                />
                <InputLabel>Email</InputLabel>
                <TextField
                    size="small"
                    label="Enter your Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="email"
                    value={userDetails.email}
                    onChange={handleChange}
                    error={!!error && (!userDetails.email || !isValidEmail(userDetails.email))}
                />
                <InputLabel>Mobile</InputLabel>
                <TextField
                    size="small"
                    label="Enter your Mobile"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="mobile"
                    value={userDetails.mobile}
                    onChange={handleChange}
                    error={!!error && (!userDetails.mobile || userDetails.mobile.length !== 10)}
                />
                <InputLabel>Password</InputLabel>
                <TextField
                    size="small"
                    label="Enter your Password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="password"
                    name="password"
                    value={userDetails.password}
                    onChange={handleChange}
                    error={!!error && !userDetails.password}
                />
                {error && <Typography color="error" variant="body2" sx={{ mt: 1 }}>{error}</Typography>}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                            },
                        }}
                        onClick={postDetails}
                        disabled={loading}
                        fullWidth
                    >
                        {loading ? <CircularProgress size={24} /> : 'Register'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Register;
