import React, { useContext, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import apiUrl from "../../config";
import { Context } from "../../context/UserContext";

const ProductForm = () => {
  const { adminToken } = useContext(Context);
  const [title, setTitle] = useState("");
  const [quantity, setQuantity] = useState([
    { weight: "", originalPrice: "", discountPrice: "" },
  ]);
  const [ingredients, setIngredients] = useState("");
  const [nutritionInformation, setNutritionInformation] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [bulletins, setBulletins] = useState("");
  const [subtitles, setSubtitles] = useState("");
  const [instock, setInStock] = useState();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImagePreview("");
  };

  const handleQuantityChange = (index, field, value) => {
    const updatedQuantity = [...quantity];
    updatedQuantity[index][field] = value;
    setQuantity(updatedQuantity);
  };

  const handleAddQuantity = () => {
    setQuantity([
      ...quantity,
      { weight: "", originalPrice: "", discountPrice: "" },
    ]);
  };

  const handleRemoveQuantity = (index) => {
    if (quantity.length > 1) {
      const updatedQuantity = quantity.filter((_, i) => i !== index);
      setQuantity(updatedQuantity);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("quantity", JSON.stringify(quantity));
    formData.append("ingredients", ingredients);
    formData.append("nutritionInformation", nutritionInformation);
    formData.append("bulletins", bulletins);
    formData.append("subtitles", subtitles);
    formData.append("instock", instock);
    if (image) {
      formData.append("image", image);
    }
    try {
      const response = await axios.post(apiUrl + "addProduct", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: adminToken,
        },
      });
      if (response.status === 200) {
        alert("Product created successfully");
      }
    } catch (error) {
      console.error("Error creating product:", error);
      alert("Error creating product");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        mx: "auto",
        p: 3,
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        boxShadow: 3,
        marginTop: "10px",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Create Product
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth>
            <InputLabel sx={{alignSelf:"center"}}>Stock*</InputLabel>
            <Select
              size="small"
              label="Stock"
              fullWidth
              value={instock}
              onChange={(e) => setInStock(e.target.value)}
              required
              sx={{ mb: 1 }}
            >
              <MenuItem value={true}>Available Now</MenuItem>
              <MenuItem value={false}>Out Of Stock</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="SubTitles"
            fullWidth
            value={subtitles}
            onChange={(e) => setSubtitles(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Bulletins"
            fullWidth
            value={bulletins}
            onChange={(e) => setBulletins(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        {quantity.map((item, index) => (
          <React.Fragment key={index}>
            <Grid container item spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  label="Weight"
                  fullWidth
                  value={item.weight}
                  onChange={(e) =>
                    handleQuantityChange(index, "weight", e.target.value)
                  }
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  label="Original Price"
                  fullWidth
                  value={item.originalPrice}
                  onChange={(e) =>
                    handleQuantityChange(index, "originalPrice", e.target.value)
                  }
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  size="small"
                  label="Discount Price"
                  fullWidth
                  value={item.discountPrice}
                  onChange={(e) =>
                    handleQuantityChange(index, "discountPrice", e.target.value)
                  }
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              {quantity.length > 1 && (
                <Grid item xs={12} sm={1}>
                  <IconButton onClick={() => handleRemoveQuantity(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleAddQuantity}
            sx={{ mb: 2 }}
          >
            Add Weight
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Ingredients"
            fullWidth
            value={ingredients}
            onChange={(e) => setIngredients(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Nutrition Information"
            fullWidth
            multiline
            rows={4}
            value={nutritionInformation}
            onChange={(e) => setNutritionInformation(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12}>
          {!image && (
            <Button variant="contained" component="label" sx={{ mb: 2 }}>
              Upload Image
              <input
                type="file"
                hidden
                onChange={handleImageChange}
                accept="image/*"
              />
            </Button>
          )}
        </Grid>
        {imagePreview && (
          <Grid item xs={12}>
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                width: "100%",
                textAlign: "center",
              }}
            >
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  width: "100%",
                  maxHeight: 300,
                  objectFit: "contain",
                  marginTop: 10,
                }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "#fff",
                }}
                onClick={handleRemoveImage}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Create Product
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
