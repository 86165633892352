import React, { useContext, useEffect, useState } from "react";
import logo from "../../../Assets/navlogo.png"
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, InputLabel, MenuItem, Select, TextField, Typography, Popover, Radio, } from "@mui/material";
import apiUrl from "../../../config";
import { Context } from "../../../context/UserContext";
import useResponsive from "../../../hooks/useResponsive";
import { states } from "../../../config";

const BillingPage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "address-popover" : undefined;
  const { myprofile, UserProfile, userToken } = useContext(Context);
  const { isSmallScreen } = useResponsive();
  const [updateIndex, setUpdateIndex] = useState(null);
  const [address, setAddress] = useState([]);
  const location = useLocation();
  const [clickedEdit, setClickedEdit] = useState(false);
  const items = location.state;
  const navigate = useNavigate();
  const [sizeBilling, setsizeBilling] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [cartlist, setCartlist] = useState([]);
  const [error, setError] = useState("");
  const [selectedAddressIndex,setSelectedAddressIndex]=useState(0)
  const [userDetails, setUserDetails] = useState({
    address: {
      streetAddress: "",
      country: "",
      state: "",
      district: "",
      city: "",
      pincode: "",
      mobile: "",
    },
  });

  useEffect(() => {
    setCartlist(myprofile?.cart || []);
  }, [myprofile]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addNewBillingAddress = async () => {
    if (!sizeBilling) {
      try {
        const { streetAddress, country, state, pincode, mobile, city, district, } = userDetails.address;
        if (!streetAddress || !country || !state || !pincode || !mobile || !city || !district) {
          setError("Please fill out all address fields.");
          return;
        }
        if (pincode.trim().length < 6) {
          setError("Pincode must be at least 6 characters.");
          return;
        }
        if (mobile.trim().length < 10) {
          setError("Please enter a valid mobile number.");
          return;
        }

        const res = await axios.post(apiUrl + `addaddress`, userDetails, {
          headers: {
            "Content-Type": "application/json",
            token: userToken,
          },
        });

        setError("");
        if (res.status === 200) {
          UserProfile();
          setError("");
          setClickedEdit(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { streetAddress, country, state, pincode, mobile, city, district, } = userDetails.address;

        if (!streetAddress || !country || !state || !pincode || !mobile || !city || !district) {
          setError("Please fill out all address fields.");
          return;
        }
        if (pincode.trim().length < 6) {
          setError("Pincode must be at least 6 characters.");
          return;
        }
        if (mobile.trim().length < 10) {
          setError("Please enter a valid mobile number.");
          return;
        }
        const res = await axios.post(apiUrl + `updateaddress`, { updateIndex, userDetails }, {
          headers: {
            "Content-Type": "application/json",
            token: userToken,
          },
        }
        );

        if (res.status === 200) {
          UserProfile();
          setError("");
          setClickedEdit(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlePaymentVerification = async (paymentResponse, amount) => {
    try {
      const res = await axios.post(apiUrl + "paymentverification", {
        products: cartlist.map((item) => ({
          productId: item.productId,
          quantity: item.quantity,
          price: item.price,
          size: item.size,
        })),
        amount: amount,
        address: selectedAddress,
        orderId: "MHPRODU" + randomNumber,
        paymentResponse,
      },
        {
          headers: {
            "Content-Type": "application/json",
            token: userToken,
          },
        }
      );
      if (res.data.success === true) {
        UserProfile();
        navigate("/profile/orderHistory");
      }
    } catch (err) {
      console.error("Error during payment verification:", err);
    }
  };

  const checkoutHandler = async () => {
    if (!selectedAddress || myprofile.address?.length === 0) {
      return setError("Please select or add a primary address before placing your order.");
    }
    if (selectedAddress.pincode===undefined||selectedAddress.pincode===null) {
      return setError("Please select or add a primary address before placing your order.");
    }
    try {
      const response = await axios.post(apiUrl + "checkout", { amount: parseInt(finalTotalAmount) },
        {
          headers: {
            token: userToken,
          },
        }
      );
      if (response.data.success === true) {
        var options = {
          key: "rzp_live_uvt5GuxgCCRtw1", //live keys
          amount: response.data.order.amount,
          currency: "INR",
          name: "Millets Heaven", //Name for razorpay
          description: "Order Transaction", // Description  for razorpay
          image: logo,
          order_id: response.data.order.id,
          theme: {
            color: "#3399cc",
          },
          handler: function (paymentResponse) {
            handlePaymentVerification({
              amount: response.data.order.amount,
              paymentResponse: paymentResponse,
            });
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    } catch (err) {
      console.log(
        "Error during checkout:",
        err.response ? err.response.data : err.message
      );
    }
  };

  const cancelToBack = async () => {
    setClickedEdit(false);
    setError("");
  };

  const handleRemoveAddress = async (id) => {
    try {
      const response = await axios.post(apiUrl + `removeaddress`, { addressId: id }, {
        headers: {
          "Content-Type": "application/json",
          token: userToken,
        },
      }
      );
      if (response.status === 200) {
        UserProfile();
      }
    } catch (error) {
      console.error("Error removing billing address:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({ ...prevUserDetails, address: { ...prevUserDetails.address, [name]: value }, }));
    if (name === "city" || name === "state" || name === "district") {
      const trimmedValue = value.replace(/^\s+/g, "");
      const nonNumericValue = trimmedValue.replace(/\d/g, "");
      setUserDetails((prevUserDetails) => ({ ...prevUserDetails, address: { ...prevUserDetails.address, [name]: nonNumericValue }, }));
    }
    if (name === "streetAddress") {
      const trimmedValue = value.replace(/^\s+/g, "");
      setUserDetails((prevUserDetails) => ({ ...prevUserDetails, address: { ...prevUserDetails.address, [name]: trimmedValue }, }));
    }
    setError("");
  };

  useEffect(() => {
    if (address && address.length > 0) {
      const delivaryaddress = {
        streetAddress: address[0].streetAddress,
        state: address[0].state,
        city: address[0].city,
        country: address[0].country,
        district: address[0].district,
        pincode: address[0].pincode,
        mobile: address[0].mobile,
      };
      setSelectedAddress(delivaryaddress);
    }
    handleDelivaryaddress(address);
  }, [address]);

  const handleDelivaryaddress = (address, index) => {
    const delivaryaddress = {
      streetAddress: address.streetAddress,
      state: address.state,
      city: address.city,
      country: address.country,
      district: address.district,
      pincode: address.pincode,
      mobile: address.mobile,
    };
    setSelectedAddressIndex(index)
    setSelectedAddress(delivaryaddress);
  };

  const addNewBilling = (address, addressId) => {
    setClickedEdit(true);
    setsizeBilling(true);
    setUpdateIndex(addressId);
    if (address) {
      const { streetAddress, country, state, district, city, pincode, mobile } = address;
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        address: { ...prevUserDetails.address, streetAddress, country, state, district, city, pincode, mobile, }
      }));
    }
  };

  useEffect(() => {
    setAddress(myprofile?.addresses || []);
  }, [myprofile]);

  const subtotal = items?.reduce((total, item) => {
    let itemTotal = 0;
    JSON.parse(item.quantity).forEach((qty) => {
      const itemWeight = qty.weight.trim();
      const cartItem = cartlist.find((cart) => cart.productId === String(item.id) && cart.size.trim() === itemWeight);
      if (cartItem) {
        const discountPrice = parseInt(qty.discountPrice);
        itemTotal += discountPrice * cartItem.quantity;
      }
    });
    return total + itemTotal;
  }, 0).toFixed(2);


  let billingProducts = handleBillingItems(items, cartlist);

  const deliveryCharge = () => {
    let totalPacketsPrice = 0;
    const totalPackets = billingProducts.reduce((acc, item) => acc + item.quantity, 0);

    if (totalPackets == 1) {
      totalPacketsPrice += 50;
    } else if (totalPackets == 2) {
      totalPacketsPrice += 90;
    } else if (totalPackets == 3) {
      totalPacketsPrice += 130;
    } else if (totalPackets > 3) {
      totalPacketsPrice = totalPackets * 40;
    }

    const charge = totalPacketsPrice;
    return charge;
  };
  // const total = Number(subtotal) + deliveryCharge();

  // const charge = deliveryCharge();
  const finalTotalAmount = (parseFloat(subtotal) + deliveryCharge()).toFixed(2);
  const randomNumber = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;

  function handleBillingItems(items, cartlist) {
    return cartlist.map((cartItem) => {
      let product = items.find((item) => item.id.toString() === cartItem.productId);
      if (product) {
        let quantityDetails = JSON.parse(product.quantity).find((q) => q.weight === cartItem.size);
        if (quantityDetails) {
          return {
            cartItemId: cartItem.id,
            productId: product.id,
            title: product.title,
            subtitles: product.subtitles,
            bulletins: product.bulletins,
            size: cartItem.size,
            quantity: cartItem.quantity,
            price: cartItem.price,
            discountPrice: quantityDetails.discountPrice,
            originalPrice: quantityDetails.originalPrice,
            image: product.image,
            nutritionInformation: product.nutritionInformation,
            createdAt: product.createdAt,
            updatedAt: product.updatedAt,
          };
        }
      }
      return null;
    })
      .filter((item) => item !== null);
  }

  return (
    <>
      <Box
        sx={{
          display: isSmallScreen ? "block" : "flex",
          justifyContent: "space-around",
          padding: isSmallScreen ? "10px" : "",
          marginTop: 2,
        }}
      >
        <Box sx={{ width: isSmallScreen ? "100%" : "50%" }}>
          <Typography variant="h4">Billing Information</Typography>
          {myprofile.address?.length > 0 ? myprofile.address.map((address, index) => (
            <Box
              key={index}
              sx={{
                marginBottom: "1rem",
                marginTop: "15px",
                border: "2px solid #aaa",
                borderRadius: "8px",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Radio
                    id={`address_${index}`}
                    name="address"
                    sx={{
                      marginRight: "0.5rem",
                      color: index ===selectedAddressIndex ? "primary.main" : "initial",
                      "&.Mui-checked": { color: "primary.main" },
                    }}
                    value={address.streetAddress}
                    checked={index === selectedAddressIndex}
                    onChange={() => handleDelivaryaddress(address, index)}
                  />
                  <InputLabel className="address-radio-btn">{`Address ${index + 1}`}</InputLabel>
                </Box>
                <Box>
                  <Button
                    className="card-delete-btn"
                    aria-describedby={id}
                    onClick={handleClick}
                    size="large"
                    sx={{ "& .MuiButton-startIcon": { color: "primary.main" } }}
                    startIcon={<MoreVertIcon />}
                  ></Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem onClick={() => handleRemoveAddress(address.id)}>
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => addNewBilling(address, address.id)}>
                      Edit
                    </MenuItem>
                  </Popover>
                </Box>
              </Box>
              <Box sx={{ marginLeft: "1rem" }}>
                <Typography>
                  {address?.streetAddress}, {address?.city}
                </Typography>
                <Typography>
                  {address?.district}, {address?.state}, {address?.country}
                </Typography>
                <Typography style={{ marginTop: "0.5rem" }}>
                  <strong>Pin code :</strong> {address?.pincode}
                </Typography>
                <Typography style={{ marginTop: "0.5rem" }}>
                  <strong>Phone Number:</strong>{" "}
                  {address?.mobile ? address?.mobile : myprofile.mobile}
                </Typography>
              </Box>
            </Box>
            ))
            : !clickedEdit && (
          <Typography variant="body1">No address added yet.</Typography>
            )}
          {clickedEdit && (
            <Box>
              <InputLabel htmlFor="streetAddress">Street Address</InputLabel>
              <TextField
                size="small"
                id="streetAddress"
                label="Enter your street address"
                variant="outlined"
                margin="normal"
                fullWidth
                name="streetAddress"
                error={error && !userDetails.address.streetAddress}
                value={userDetails.address.streetAddress}
                onChange={handleInputChange}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  <Box sx={{ flex: 1 }}>
                    <InputLabel htmlFor="city">City</InputLabel>
                    <TextField
                      size="small"
                      id="city"
                      label="Enter your city"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="city"
                      error={error && !userDetails.address.city}
                      value={userDetails.address.city}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <InputLabel htmlFor="district">District</InputLabel>
                    <TextField
                      size="small"
                      id="district"
                      label="Enter your district"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="district"
                      error={error && !userDetails.address.district}
                      value={userDetails.address.district}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  <Box sx={{ flex: 1 }}>
                    <InputLabel htmlFor="state">State</InputLabel>
                    <Select
                      size="small"
                      id="state"
                      value={userDetails.address.state}
                      onChange={handleInputChange}
                      fullWidth
                      name="state"
                      variant="outlined"
                      error={error && !userDetails.address.state}
                      sx={{ mt: 2 }}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <InputLabel htmlFor="zipcode">Zipcode</InputLabel>
                    <TextField
                      size="small"
                      id="zipcode"
                      label="Enter your zipcode"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="pincode"
                      error={error && !userDetails.address.pincode}
                      value={userDetails.address.pincode}
                      onChange={handleInputChange}
                    />
                  </Box>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="country">Country</InputLabel>
                  <TextField
                    size="small"
                    id="country"
                    label="Enter your country"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="country"
                    error={error && !userDetails.address.country}
                    value={userDetails.address.country}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>
              <InputLabel htmlFor="mobile">Phone Number</InputLabel>
              <TextField
                size="small"
                id="mobile"
                label="Enter your mobile"
                variant="outlined"
                margin="normal"
                fullWidth
                name="mobile"
                error={error && !userDetails.address.mobile}
                value={userDetails.address.mobile}
                onChange={handleInputChange}
              />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button onClick={cancelToBack}>Cancel</Button>
                <Button
                  onClick={addNewBillingAddress}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "#ffff",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
          {!clickedEdit && (
            <Button
              onClick={() => setClickedEdit(true)}
              sx={{
                backgroundColor: "primary.main",
                color: "#ffff",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              Add Address
            </Button>
          )}
        </Box>
        <Box sx={{ width: isSmallScreen ? "100%" : "30%" }}>
          <Typography variant="h5">Order Summary</Typography>
          <Box sx={{ marginTop: 4 }}>
            {items && items.length > 0 ? (
              <Box
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "8px",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                {billingProducts.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        borderBottom: "1px solid #D9D9D9",
                        padding: "10px 0",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={item.image}
                          alt="Product"
                          style={{ width: "50px", marginRight: "10px" }}
                        />
                        <Typography style={{ flex: 1 }}>
                          {item.title} ({item.size})grams x {item.quantity}
                        </Typography>{" "}
                        <Typography style={{ textAlign: "right" }}>
                          ₹{(item.discountPrice * item.quantity).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <Typography>Subtotal:</Typography>
                  <Typography>₹{subtotal}</Typography>
                </Box>
                <hr style={{ borderColor: "#D9D9D9" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0",
                  }}
                >
                  <Typography>Shipping:</Typography>
                  <Typography>
                    {!selectedAddress ? (
                      <Typography>Please select a delivery address</Typography>
                    ) : (
                      <Typography>₹{deliveryCharge().toFixed(2)}</Typography>
                    )}
                  </Typography>
                </Box>
                <hr style={{ borderColor: "#D9D9D9" }} />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0",
                  }}
                >
                  <Typography>Total:</Typography>
                  <Typography>₹{finalTotalAmount}</Typography>
                </Box>
                <Typography style={{ marginTop: "20px", marginBottom: "10px" }}>
                  Payment Method
                </Typography>
                <Box
                  style={{
                    marginBottom: "20px",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                />
                {error && (
                  <Typography variant="body1" style={{ color: "red" }}>
                    {error}{" "}
                  </Typography>
                )}
               <Button
                  variant="button"
                  onClick={checkoutHandler}
                  sx={{
                    display: "block",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    width: "100%",
                    color: "black",
                  }}
                >
                  Place Order
                </Button>
                <br />
              </Box>
            ) : (
              <Typography>No items in the cart</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BillingPage;
