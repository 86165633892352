import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Register from '../components/Forms/Register';
import Login from '../components/Forms/Login';
import BillingPage from '../pages/UserPages/DashBoard/BillingPage';
import OrderHistory from '../pages/UserPages/DashBoard/OrderHistory';
import Cart from '../pages/UserPages/DashBoard/Cart';
import Products from '../pages/UserPages/Products';
import OrderList from '../pages/AdminPages/OrderLists';
import About from '../pages/UserPages/About';
import Home from '../pages/Home';
import Community from '../pages/UserPages/Community';
import ContactUs from '../pages/UserPages/ContactUs';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import RefundandCancellation from '../pages/RefundandCancellation';
import DeliveryandShippingPolicy from '../pages/DeliveryandShippingPolicy';
import Profile from '../pages/UserPages/DashBoard/Profile';
import PersonalInfo from '../pages/UserPages/DashBoard/PersonalInfo';
import ProductList from '../pages/AdminPages/ProductList';
import ProductForm from '../pages/AdminPages/ProductForm';
import PageNotFound from '../components/PageNotFound';
import ForgotPassword from '../components/Forms/ForgotPassword';
import ResetPassword from '../components/Forms/RestPassword';
import ContactUsList from '../pages/AdminPages/ContactUsList';

const PageRouting = () => {
    const removeCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    };

    const handleLogout = () => {
        removeCookie('userToken');
    };

    return (
        <div>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgetpassword" element={<ForgotPassword />} />
                <Route path='/products' element={<Products />} />
                <Route path='/about' element={<About />} />
                <Route path='/community' element={<Community />} />
                <Route path='/contactus' element={<ContactUs />} />
                <Route path='/terms&conditions' element={<TermsAndConditions />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/refundandcancellation' element={<RefundandCancellation />} />
                <Route path='/deliveryandshipping' element={<DeliveryandShippingPolicy />} />
                <Route path='/forgotPassword' element={<ForgotPassword />} />
                <Route path="/resetPassword/:token" element={<ResetPassword />} />
                <Route path='/profile' element={<ProtectedRoute userelement={<Profile handleLogout={handleLogout} />} />} >
                    <Route path='' element={<ProtectedRoute userelement={<PersonalInfo />} />} />
                    <Route path='cart' element={<ProtectedRoute userelement={<Cart />} />} />
                    <Route path='orderHistory' element={<ProtectedRoute userelement={<OrderHistory />} />} />
                </Route>
                <Route path='/billing' element={<ProtectedRoute userelement={<BillingPage />} />} />
                <Route path='/admin'>
                    <Route path='' element={<ProtectedRoute adminelement={<OrderList />} />} />
                    <Route path='addproduct' element={<ProtectedRoute adminelement={< ProductForm />} />} />
                    <Route path='productlist' element={<ProtectedRoute adminelement={< ProductList />} />} />
                    <Route path='contactuslist' element={<ProtectedRoute adminelement={<ContactUsList />} />} />
                </Route>

                <Route
                    path="*"
                    element={<PageNotFound />}
                />
            </Routes>
        </div>
    );
};

export default PageRouting;
