import React, { useEffect } from "react";
import { Container, Typography, List, ListItem, ListItemText } from "@mui/material";

const DeliveryandShippingPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <Container maxWidth="lg" sx={{paddingY:10,}}>
            <Typography variant="h4" textAlign={"center"}  gutterBottom>
                Delivery and Shipping Policy
            </Typography>
            <Typography variant="body1" paragraph>
                Thank you for choosing Milletheaven. We are dedicated to providing you with the best service
                and ensuring that your order reaches you in a timely manner. Please read the following
                information regarding our delivery and shipping policies:
            </Typography>
            <Typography variant="h6" gutterBottom>
                Order Processing Time:
            </Typography>
            <Typography variant="body1" paragraph>
                All orders are processed within 2-3 business days after they are placed, excluding weekends
                and holidays. Orders placed after 12:00 PM IST will be processed the next business day.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Shipping Rates:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        Shipping charges are calculated based on the weight of your order and the destination
                        address.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        We offer flat-rate shipping for domestic orders shipping options are available with
                        varying rates.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
                Delivery Time:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        Delivery times vary depending on the shipping method selected during checkout and the
                        destination of the package.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        Domestic orders typically arrive within 3-7 business days after they have been processed.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
                Order Tracking:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        Once your order has been shipped, you will receive a confirmation email or what's up with
                        tracking information.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
                Shipping Carriers:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        We partner with reliable shipping carriers to ensure that your order is delivered safely
                        and on time.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
                Shipping Address:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        Please ensure that the shipping address provided during checkout is accurate and complete.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        We are not responsible for orders shipped to incorrect or incomplete addresses provided
                        by the customer.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
                Delivery Issues:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        In the event of any delivery issues such as lost or damaged packages, please contact our
                        customer service team immediately for assistance.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        We will work with the shipping carrier to resolve the issue and ensure that you receive
                        your order as soon as possible.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="h6" gutterBottom>
                Additional Information:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        All delivery and shipping times are estimates and may vary depending on external factors
                        such as weather conditions, holidays, and carrier delays.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        We are not responsible for delays caused by factors beyond our control, but we will do
                        our best to assist you in any way we can.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="body1" paragraph>
                If you have any further questions or concerns regarding our delivery and shipping policies,
                please feel free to contact our customer service team for assistance.
            </Typography>
            <Typography variant="body1">
                Thank you for choosing Milletheaven!
            </Typography>
        </Container>
    );
};

export default DeliveryandShippingPolicy;
