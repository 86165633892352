import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
    Container, Grid, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Pagination, Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import apiUrl from "../../config.js";

const ContactUsList = () => {
    const [contactsList, setContactsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        fetchContacts();
    }, []);

    const fetchContacts = async () => {
        try {
            const res = await axios.get(`${apiUrl}getcontacts`, { headers: { "Content-Type": "application/json" } });
            setContactsList(res.data.data);
        } catch (error) {
            console.error("Failed to fetch contacts", error);
        }
    };
    const filteredContacts = useMemo(() => {
        return contactsList?.filter(contact =>
            contact.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            contact.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            contact.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            contact.mobile.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [contactsList, searchQuery]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredContacts.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredContacts.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h5" gutterBottom>Contact Us List</Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Search"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel>Items Per Page</InputLabel>
                        <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange} 
                            label="Items Per Page"
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No.</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.length === 0 && (
                            <TableRow><TableCell colSpan={7} align="center">No contacts found.</TableCell></TableRow>
                        )}
                        {currentItems.map((contact, index) => {
                            const contactIndex = indexOfFirstItem + index;
                            return (
                                <TableRow key={contactIndex}>
                                    <TableCell>{contactIndex + 1}</TableCell>
                                    <TableCell>{contact.firstName}</TableCell>
                                    <TableCell>{contact.lastName}</TableCell>
                                    <TableCell>{contact.mobile}</TableCell>
                                    <TableCell>{contact.email}</TableCell>
                                    <TableCell>{contact.address}</TableCell>
                                    <TableCell>{contact.message}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={pageNumbers.length}
                page={currentPage}
                onChange={(e, page) => paginate(page)}
                color="primary"
                style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
            />
        </Container>
    );
};

export default ContactUsList;
