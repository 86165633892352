import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Farm from "../../Assets/Farm.png";
import Millet_loga from "../../Assets/MilletHeaven.png";
import mobilefarm from "../../Assets/mobilefarm.png";
import useResponsive from "../../hooks/useResponsive";
const HomeFooterSection = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const loadimage =  isSmallScreen? mobilefarm : Farm

  return (
    <>
      <Box sx={{ backgroundColor: "primary.background" }}>
        <Box
          style={{
            backgroundImage: `url(${loadimage})`,
            backgroundSize: isSmallScreen? 479.68 :"cover",
            backgroundRepeat: "no-repeat",
            height: isSmallScreen?"286px":isMediumScreen?"506px":"980px",
            display: "flex",
            justifyContent: "End",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: isSmallScreen ? "95%" : isMediumScreen ? "70%" : "60%",
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              textAlign={"center"}
              sx={{ marginTop: isMediumScreen ? "60px" : "145px" , color:"#554200", fontSize:isSmallScreen ? "10px" : isMediumScreen ? "18px" : "23px", lineHeight:isSmallScreen? 1:1.5 }}
            >
              Welcome to Millet Heaven, where wholesome nutrition meets
              delectable taste. Our brand is dedicated to crafting products that
              harmoniously blend the finest millets and an array of premium dry
              fruits. The result? A deliciously unique offering that not only
              tantalizes your taste buds but also nourishes your body with every
              bite. Millet Heaven stands out by embracing the ancient goodness
              of millets, known for their rich nutrient profile and numerous
              health benefits. By pairing these powerhouse grains with a
              selection of handpicked dry fruits, we create an unparalleled
              combination that supports a balanced diet and a vibrant lifestyle.
            </Typography>
          </Box>
          <Box sx={{ height:isSmallScreen?"auto": "50%", width:isSmallScreen?"120px":isMediumScreen?"260px":"640px"}}>
            <img src={Millet_loga} alt="Millet Heaven" width="100%" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeFooterSection;
