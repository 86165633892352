import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../context/UserContext";
import { Box, Button, Container, Divider, Grid, IconButton, Typography, Paper, Modal, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import apiUrl from "../../../config";

const Cart = () => {
    const { myprofile, UserProfile, userToken } = useContext(Context);
    const [cartlist, setCartlist] = useState([]);
    const [cartItem, setcartItem] = useState([])
    const [subtotal, setSubtotal] = useState(0);
    const [deliveryCharges, setDeliveryCharges] = useState(0);
    const [total, setTotal] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setCartlist(myprofile?.cart || []);
        setcartItem(myprofile?.cartProducts || [])
    }, [myprofile]);

    useEffect(() => {
        if (cartlist?.length > 0) {
            let subTotalAmount = cartlist.reduce((acc, item) => acc + item.price * item.quantity, 0);
            setSubtotal(subTotalAmount);
            setDeliveryCharges(deliveryCharge())
            setTotal(subTotalAmount+deliveryCharges);
        } else {
            setSubtotal(0);
            setTotal(0);
        }
    }, [cartlist]);

    const handleProceedToCheckout = () => {
        if (cartlist.length === 0) {
            setModalOpen(true);
        } else {
            navigate("/billing", { state: cartItem });
        }
    };

    const increaseQuantity = (id) => {
        setCartlist(
            cartlist.map((item) => {
                if (item.id === id) {
                    return { ...item, quantity: item.quantity + 1 };
                }
                return item;
            })
        );
        updateCartQuantity(id, "inc");
    };

    const decreaseQuantity = (id) => {
        setCartlist(
            cartlist.map((item) => {
                if (item.id === id && item.quantity > 1) {
                    return { ...item, quantity: item.quantity - 1 };
                }
                if (item.id === id && item.quantity === 1) {
                    removeItem(id);
                }
                return item;
            })
        );
        updateCartQuantity(id, "dec");
    };

    const updateCartQuantity = async (id, scope) => {
        try {
            const res = await axios.post(apiUrl + `updatacartquantity`, { cartId: id, scope: scope, }, {
                headers: {
                    "Content-Type": "application/json",
                    "token": userToken
                },
            });
            if (res.status === 200) {
                UserProfile();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const removeItem = async (id) => {
        try {
            const res = await axios.post(apiUrl + `removeCart`, { cartId: id, }, {
                headers: {
                    "Content-Type": "application/json",
                    "token": userToken
                },
            });
            if (res.status === 200) {
                UserProfile();
            }
        } catch (error) {
            console.log(error);
        }
    };

    function handleCartListItems(items, cartlist) {
        return cartlist.map(cartItem => {
            let product = items.find(item => item.id.toString() === cartItem.productId);
            if (product) {
                let quantityDetails =JSON.parse(product.quantity).find(q => q.weight === cartItem.size);
                if (quantityDetails) {
                    return {
                        cartItemId: cartItem.id,
                        productId: product.id,
                        title: product.title,
                        size: cartItem.size,
                        quantity: cartItem.quantity,
                        discountPrice: quantityDetails.discountPrice,
                        originalPrice: quantityDetails.originalPrice,
                        image: product.image,
                    };
                }
            }
            return null;
        }).filter(item => item !== null);
    }
    let cartProductsList = handleCartListItems(cartItem, cartlist);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const deliveryCharge = () => {
        let totalPacketsPrice = 0;
        const totalPackets = cartProductsList.reduce((acc, item) => acc + item.quantity, 0);
      
        if (totalPackets == 1) {
          totalPacketsPrice += 50;
        } else if (totalPackets == 2) {
          totalPacketsPrice += 90;
        } else if (totalPackets == 3) {
          totalPacketsPrice += 130;
        } else if (totalPackets > 3) {
          totalPacketsPrice = totalPackets * 40;
        }
      
        const charge = totalPacketsPrice;
        return charge;
      
      };

    return (
        <>
            <Modal open={modalOpen} onClose={handleCloseModal}
                aria-labelledby="empty-cart-modal"
                aria-describedby="empty-cart-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="empty-cart-modal" variant="h6" component="h2">Your cart is currently empty</Typography>
                    <Typography id="empty-cart-modal-description" sx={{ mt: 2 }}>Please add some items to proceed with your purchase.</Typography>
                    <Box textAlign="center" marginTop={2}>
                        <Button onClick={handleCloseModal} variant="contained">Close</Button>
                    </Box>
                </Box>
            </Modal>
            <Container>
                <Typography variant="h4" textAlign="center" marginBottom={5} paddingY={4}>My Shopping Cart<Divider /></Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: "primary.main" }}>
                                            <TableCell><Typography variant="body1">PRODUCT</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="body1">PRICE</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="body1">QUANTITY</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="body1">SUBTOTAL</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="body1">ACTION</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cartProductsList.length > 0 ? (
                                            cartProductsList.map((item, index) => {
                                                const subtotal = item.discountPrice * item.quantity;
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell><Typography variant="body1">{item.title} ({item.size})</Typography></TableCell>
                                                        <TableCell align="right"><Typography variant="h6">₹{item.discountPrice}</Typography></TableCell>
                                                        <TableCell align="right">
                                                            <Box display="flex" alignItems="center">
                                                                <IconButton onClick={() => decreaseQuantity(item.cartItemId)}>
                                                                    <RemoveCircleIcon color="action" />
                                                                </IconButton>
                                                                <Typography>{item.quantity}</Typography>
                                                                <IconButton onClick={() => increaseQuantity(item.cartItemId)}>
                                                                    <AddCircleIcon color="action" />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right"><Typography>₹{subtotal.toFixed(2)}</Typography></TableCell>
                                                        <TableCell align="right">
                                                            <IconButton onClick={() => removeItem(item.cartItemId)}>
                                                                <DeleteIcon color="action" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant="h6" textAlign="center">Your cart is empty. Please add items to continue shopping.</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box textAlign="center" sx={{ marginTop: 2 }}>
                                <Button component={Link} to="/products" variant="contained" sx={{
                                    backgroundColor: "primary.main",
                                    '&:hover': {
                                        backgroundColor: "primary.dark"
                                    },
                                    marginBottom: 2
                                }}>
                                    Return to shop
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ marginBottom: 5 }}>
                            <Box padding={2}>
                                <Typography variant="h5">Cart Total</Typography>
                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Typography variant="h6">Subtotal:</Typography>
                                    <Typography variant="h6">₹{subtotal.toFixed(2)}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Typography variant="h6">Delivery charges extra:</Typography>
                                    <Typography variant="h6">₹{deliveryCharge().toFixed(2)}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Typography variant="h4">Total:</Typography>
                                    <Typography variant="h6">₹{(total).toFixed(2)}</Typography>
                                </Box>
                                <Button
                                    sx={{
                                        backgroundColor: "primary.main", '&:hover': {
                                            backgroundColor: "primary.main"
                                        }, color: "black"
                                    }}
                                    fullWidth
                                    onClick={handleProceedToCheckout}
                                    style={{ marginTop: 10 }}
                                >
                                    Proceed to checkout
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Cart;
