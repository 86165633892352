import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../context/UserContext';
import apiUrl from '../../../config';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Box, Button, Divider, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { states } from "../../../config";
import useResponsive from '../../../hooks/useResponsive';

const PersonalInfo = () => {
  const { myprofile, UserProfile, userToken } = useContext(Context);
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [profileAddress, setProfileAddress] = useState({});
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [addressErrors, setAddressErrors] = useState({
    streetAddress: "",
    country: "",
    state: "",
    district: "",
    city: "",
    pincode: "",
  });

  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  
  const [userDetails, setUserDetails] = useState({
    address: {
      streetAddress: "",
      country: "",
      state: "",
      district: "",
      city: "",
      pincode: "",
    },
  });

  useEffect(() => {
    if (myprofile) {
      setPersonalDetails({ name: myprofile.name, email: myprofile.email, mobile: myprofile.mobile, });
    }
    if (myprofile.addresses && myprofile.addresses.length > 0) {
      setProfileAddress(myprofile.addresses[0]);
      setUserDetails({
        address: {
          streetAddress: myprofile.addresses[0]?.streetAddress || "",
          country: myprofile.addresses[0]?.country || "",
          state: myprofile.addresses[0]?.state || "",
          district: myprofile.addresses[0]?.district || "",
          city: myprofile.addresses[0]?.city || "",
          pincode: myprofile.addresses[0]?.pincode || "",
        },
      })
    }
  }, [myprofile]);

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    if (section === "personal") {
      if (name === "name") {
        const trimmedValue = value.replace(/^\s+/g, "").replace(/\d/g, "");
        setPersonalDetails((prevDetails) => ({ ...prevDetails, [name]: trimmedValue, }));
      } else if (name === "email") {
        const trimmedValue = value.replace(/^\s+/g, "");
        setPersonalDetails((prevDetails) => ({ ...prevDetails, [name]: trimmedValue, }));
      } else {
        setPersonalDetails((prevDetails) => ({ ...prevDetails, [name]: value, }));
      }
      setEmailError("");
      setNameError("");
    } else if (section === "address") {
      if (name === "country" || name === "state" || name === "city" || name === "district") {
        const trimmedValue = value.replace(/^\s+/g, "").replace(/\d/g, "");
        setUserDetails((prevDetails) => ({ ...prevDetails, address: { ...prevDetails.address, [name]: trimmedValue }, }));
      } else {
        setUserDetails((prevDetails) => ({ ...prevDetails, address: { ...prevDetails.address, [name]: value }, }));
      }
      setAddressErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const savePersonalDetails = async (e) => {
    e.preventDefault();

    if (!personalDetails.name.trim()) {
      setNameError("Please enter a name");
      return;
    }

    const emailRegex = /^[^\s@]+@(gmail|yahoo|hotmail)\.com$/i;
    if (!emailRegex.test(personalDetails.email)) {
      setEmailError("Please enter a valid email address ending with gmail.com, yahoo.com, or hotmail.com.");
      return;
    }

    if (personalDetails.mobile.length < 10) {
      setMobileError("Mobile number must be at least 10 digits");
      return;
    }

    const formDataForApi = new FormData();
    for (let key in personalDetails) {
      formDataForApi.append(key, personalDetails[key]);
    }

    try {
      const res = await axios.post(`${apiUrl}updateUser`, formDataForApi,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "token": userToken
          },
        }
      );
      if (res.status === 200) {
        setEmailError("");
        setMobileError("");
        setNameError("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addNewBillingAddress = async (e) => {
    e.preventDefault();
    const { streetAddress, country, state, city, district, pincode } =
      userDetails.address;
    const newErrors = {};
    if (!streetAddress)
      newErrors.streetAddress = "Please enter your street address.";
    if (!country) newErrors.country = "Please enter your country.";
    if (!state) newErrors.state = "Please enter your state.";
    if (!city) newErrors.city = "Please enter your city.";
    if (!district) newErrors.district = "Please enter your district.";
    if (!pincode) newErrors.pincode = "Please enter your pincode.";
    if (pincode.trim().length < 6)
      newErrors.pincode = "Pincode must be at least 6 characters.";

    if (Object.keys(newErrors).length > 0) {
      setAddressErrors(newErrors);
      return;
    }

    try {
      const res = await axios.post(`${apiUrl}addaddress`, userDetails.address,
        {
          headers: {
            "Content-Type": "application/json",
            "token": userToken
          },
        }
      );
      if (res.status === 200) {
        UserProfile();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateAddress = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${apiUrl}updateaddress`, {
        updateIndex: myprofile?.addresses[0]?.id,
        address: userDetails.address,
      },
        {
          headers: {
            "Content-Type": "application/json",
            "token": userToken
          },
        }
      );
      if (res.status === 200) {
        UserProfile();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ padding: isSmallScreen ? "20px" : isMediumScreen ? "40px" : "35px", paddingY: isSmallScreen ? "20px" : isMediumScreen ? "40px" : "50px" }}>
      <Box
        className="box-border"
        sx={{ paddingTop: isSmallScreen ? "20px" : isMediumScreen ? "40px" : "25px", }}
      >
        <Typography variant="h4">Personal Information</Typography>
        <Box>
          <InputLabel htmlFor="name">Name:</InputLabel>
          <TextField
            label="Enter your Name"
            size="small"
            id="name"
            name="name"
            value={personalDetails.name}
            onChange={(e) => handleInputChange(e, "personal")}
            sx={{
              marginBottom: "16px",
              width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "auto",
            }}
            error={Boolean(nameError)}
            helperText={nameError}
          />
          <InputLabel htmlFor="email">Email:</InputLabel>
          <TextField
            label="Enter your Email"
            size="small"
            id="email"
            name="email"
            value={personalDetails.email}
            onChange={(e) => handleInputChange(e, "personal")}
            sx={{ marginBottom: "16px", width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "auto", }}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <InputLabel htmlFor="mobile">Mobile:</InputLabel>
          <TextField
            label="Enter your Mobile"
            size="small"
            id="mobile"
            name="mobile"
            value={personalDetails.mobile}
            onChange={(e) => handleInputChange(e, "personal")}
            sx={{ marginBottom: "16px", width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "auto", }}
            error={Boolean(mobileError)}
            helperText={mobileError}
          />
        </Box>
        <Button
          onClick={savePersonalDetails}
          variant="contained"
          sx={{ margin: "8px 0" }}
        >
          Save
        </Button>
      </Box>
      <Divider sx={{ margin: 2 }} />
      <Box className="box-border">
        <Typography variant="h6">Address</Typography>
        <Box>
          <Box>
            <InputLabel htmlFor="streetAddress">Street Address:</InputLabel>
            <TextField
              label="Enter your Street Address"
              fullWidth
              size="small"
              id="streetAddress"
              name="streetAddress"
              value={userDetails.address.streetAddress}
              onChange={(e) => handleInputChange(e, "address")}
              sx={{
                marginBottom: "16px",
                width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "100%",
              }}
              error={Boolean(addressErrors.streetAddress)}
              helperText={addressErrors.streetAddress}
            />
          </Box>
          <Box sx={{ display: isSmallScreen ? "block" : isMediumScreen ? "block" : "flex", gap: 0.5, }}>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="country">Country:</InputLabel>
              <TextField
                label="Enter your Country"
                size="small"
                id="country"
                name="country"
                value={userDetails.address.country}
                onChange={(e) => handleInputChange(e, "address")}
                sx={{ marginBottom: "16px", width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "auto", }}
                error={Boolean(addressErrors.country)}
                helperText={addressErrors.country}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="state">State:</InputLabel>
              <Select
                label="Select your State"
                fullWidth
                size="small"
                id="state"
                name="state"
                value={userDetails.address.state}
                onChange={(e) => handleInputChange(e, "address")}
                sx={{ marginBottom: "16px", width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "100%", }}
                error={Boolean(addressErrors.state)}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
              </Select>
              {addressErrors.state && (<Typography color="error">{addressErrors.state}</Typography>)}
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="district">District:</InputLabel>
              <TextField
                label="Enter your District"
                size="small"
                id="district"
                name="district"
                value={userDetails.address.district}
                onChange={(e) => handleInputChange(e, "address")}
                sx={{ marginBottom: "16px", width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "auto", }}
                error={Boolean(addressErrors.district)}
                helperText={addressErrors.district}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="city">City:</InputLabel>
              <TextField
                label="Enter your City"
                size="small"
                id="city"
                name="city"
                value={userDetails.address.city}
                onChange={(e) => handleInputChange(e, "address")}
                sx={{ marginBottom: "16px", width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "auto", }}
                error={Boolean(addressErrors.city)}
                helperText={addressErrors.city}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="pincode">Pincode:</InputLabel>
              <TextField
                label="Enter your Pincode"
                size="small"
                id="pincode"
                name="pincode"
                value={userDetails.address.pincode}
                onChange={(e) => handleInputChange(e, "address")}
                sx={{ marginBottom: "16px", width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "auto" }}
                error={Boolean(addressErrors.pincode)}
                helperText={addressErrors.pincode}
              />
            </Box>
          </Box>
        </Box>
        {profileAddress ? (
          <Button onClick={updateAddress}>Save Changes</Button>
        ) : (
          <Button onClick={addNewBillingAddress}>Add Address</Button>
        )}
      </Box>
    </Box>
  );
};

export default PersonalInfo;
