import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Link,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import useResponsive from "../hooks/useResponsive";

const Footer = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const styles = {
    icons: {
      height: "40px",
      width: "42px",
      backgroundColor: "#F5DDC2",
      borderRadius: "5px",
    },
    payments: {
      height: "2rem",
    },
  };

  return (
    <>
      {" "}
      <Box
        sx={{
          backgroundColor: "secondary.main",
          color: "#fff",
          p: { xs: 2, sm: 3, md: 5, lg: 8 },
        }}
      >
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Box sx={{ maxWidth: "300px", p: { xs: 1, md: 2 } }}>
              <Box
                sx={{
                  padding: "10px",
                  backgroundColor: "neutral_light.main",
                  height: "fit-content",
                  borderRadius: "15px",
                }}
              >
                <Link href={"/#/"}>
                  <img
                    src="../Assets/footerlmillet.png"
                    alt="Millet Heaven"
                    style={{ display: "fit-content", borderRadius: "5px" }}
                  />
                </Link>
              </Box>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Welcome to Millet Heaven, where nutrition meets taste. We blend
                nutrient-rich millets with premium dry fruits to create
                delicious products that support a healthy, vibrant lifestyle.
                Enjoy every bite!{" "}
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <Link href="https://www.facebook.com/profile.php?id=61565157620418">
                  <img
                    style={styles.icons}
                    src="../Assets/fbicon.png"
                    alt="Facebook"
                  />
                </Link>
                  {/* <Link to="https://www.youtube.com/@milletheaven">
                    <img
                      style={styles.icons}
                      src="../Assets/linkdin.png"
                      alt="LinkedIn"
                    />
                  </Link> */}
                <Link href="https://www.instagram.com/mill.etheaven/">
                  <img
                    style={styles.icons}
                    src="../Assets/insta.png"
                    alt="Instagram"
                  />
                </Link>
                <Link href="https://www.youtube.com/@milletheaven">
                  <img
                    style={styles.icons}
                    src="../Assets/yticon.png"
                    alt="YouTube"
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "200px",
                    p: { xs: 1, md: 2, lg: 0 },
                    paddingX: 0,
                  }}
                >
                  <Typography variant="h5">Shop</Typography>
                  <List component="nav">
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="products"
                        color="inherit"
                        sx={{ textDecoration: "none", color: "inherit" }}
                      >
                        Baby Ahar
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="products"
                        color="inherit"
                        sx={{ textDecoration: "none", color: "inherit" }}
                      >
                        Unic Millet Malt
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="products"
                        color="inherit"
                        sx={{ textDecoration: "none", color: "inherit" }}
                      >
                        Ragiwow
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="products"
                        color="inherit"
                        sx={{ textDecoration: "none", color: "inherit" }}
                      >
                        Almando
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "200px",
                    p: { xs: 1, md: 2, lg: 0 },
                  }}
                >
                  <Typography variant="h5">Policies</Typography>
                  <List component="nav">
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="#/privacypolicy"
                        color="inherit"
                        sx={{
                          textDecoration: "none",
                          color: "inherit",
                          width: "180px",
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="#/deliveryandshipping"
                        color="inherit"
                        sx={{
                          textDecoration: "none",
                          color: "inherit",
                          width: "180px",
                        }}
                      >
                        Shipping & Delivery
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="#/refundandcancellation"
                        color="inherit"
                        sx={{
                          textDecoration: "none",
                          color: "inherit",
                          width: "180px",
                        }}
                      >
                        Return & Refund
                      </Link>
                    </ListItem>
                    <ListItem sx={{ paddingX: "0px" }}>
                      <Link
                        href="#/terms&conditions"
                        color="inherit"
                        sx={{
                          textDecoration: "none",
                          color: "inherit",
                          width: "180px",
                        }}
                      >
                        Terms & Conditions
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: { xs: 1, md: 2, lg: 0 },
                  }}
                >
                  <Typography variant="h5">Contact Us</Typography>
                  <Typography variant="subtitle3">
                    Ph: +91 8688392094 / 6281169720
                  </Typography>
                  <Typography variant="subtitle3">
                    Email: info@milletheaven.com
                  </Typography>
                  <Typography variant="subtitle3">
                    Address: 5/1321/4-1, dorasanipalli road, Revenue ward No.5,
                    Rameswaram, Kadapa(dt), Andhra Pradesh, PIN:516360
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid>
              <Box
                width={"100%"}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Box
                  width={
                    isSmallScreen ? "100%" : isMediumScreen ? "100%" : "70%"
                  }
                  sx={{
                    marginRight: 0,
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Enter your e-mail id"
                    size="small"
                    sx={{
                      mt: 2,
                      mx: isSmallScreen ? 0 : isMediumScreen ? 1 : 2,
                      width: isSmallScreen ? "100%" : "80%",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                        padding: "0",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button
                          sx={{
                            backgroundColor: "secondary.main",
                            "&:hover": {
                              backgroundColor: "secondary.main",
                            },
                            color: "#fff",
                            borderRadius: "20px",
                            m: 1,
                            width: isSmallScreen
                              ? "160px"
                              : isMediumScreen
                              ? "160px"
                              : "130px",
                            alignItems: "center",
                          }}
                        >
                          Email Us
                        </Button>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginY: "25px",
                }}
              >
                <Typography textAlign={"center"}>Payment methods</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    mt: 2,
                    justifyContent: "center",
                  }}
                >
                  <img src="../Assets/Visa.png" alt="Visa" />
                  <img src="../Assets/master.png" alt="MasterCard" />
                  <img src="../Assets/american.png" alt="American Express" />
                  <img src="../Assets/paypal.png" alt="PayPal" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            mb: isMediumScreen ? 0 : -5,
          }}
        >
          <Typography variant="body2">
            Copyright © 2024, Millet Heaven
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
