import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { features } from "../../config";
import { products_data } from "../../config";
import useResponsive from "../../hooks/useResponsive";
import StarIcon from "@mui/icons-material/Star";
import { Context } from "../../context/UserContext";

const HomeProductsSection = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const { products, setSelectProd} = useContext(Context);

  const [reviews, setReviews] = useState(
    JSON.parse(localStorage.getItem("allreviews"))
  );
  const style = {
    padding: "1px !important",
    border: " 1px solid white !important",
    borderRadius: "50px !important",
    background: "white !important",
  };

  const prodrating = (id) => {
    const filteredReview = reviews?.filter(
      (review) => parseInt(review.productId) === parseInt(id)
    );
    const averageRating =
      filteredReview?.length > 0
        ? filteredReview.reduce((sum, review) => sum + review.rating, 0) /
          filteredReview.length
        : 0;

    return averageRating;
  };

  const handleselectprod=(selectIndex)=>{
    setSelectProd(selectIndex)
  }

  return (
    <>
      <Box sx={{ backgroundColor: "primary.background", paddingY: "15px", marginTop:'-5px' }}>
        <Container>
        {products &&
              products?.map((prodItems, index) => (
                  <Box
                  mb={2}
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: isSmallScreen
                        ? "row"
                        : isMediumScreen
                        ? "column"
                        : "row",
                      justifyContent: "space-evenly",
                      marginY: isSmallScreen ? "10px" : "60px",
                      padding: isSmallScreen ? "25px 15px" : "55px 15px",
                      borderRadius: "15px",
                      color: "accent2.main",
                      backgroundColor: "accent2.background",
                    }}
                  >
                    <Box
                      sx={{
                        display: isSmallScreen
                          ? "flex"
                          : isMediumScreen
                          ? "flex"
                          : "flex",
                        justifyContent: "space-around",
                        width: isSmallScreen ? "70%" : "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: isSmallScreen ? "206px" : "280px",
                          paddingX: isSmallScreen? 0:2,
                        }}
                      >
                        <img src={prodItems.image} width={"100%"} />
                      </Box>

                      <Box>
                        <Typography variant={isSmallScreen ? "10px" : "h4"}>
                          {prodItems.title}
                        </Typography>
                        <Box
                          sx={{
                            display: isSmallScreen ? "none" : "flex",

                            alignItems: "center",

                            backgroundColor: "#ffffff",

                            width: isSmallScreen ? "28px" : "70px",

                            padding: "5px",

                            borderRadius: "5px",
                          }}
                        >
                          <Typography
                            variant={isSmallScreen ? "body1" : "h6"}
                            sx={{ color: "#000", marginLeft: "5px" }}
                          >
                            {prodrating(prodItems.id)}
                          </Typography>

                          <StarIcon
                            sx={{
                              marginBottom: "5px",
                              color: "#FFD700",
                              fontSize: "20px",
                              marginLeft: "5px",
                            }}
                          />
                        </Box>

                        <Typography mt={1}
                          sx={{
                            fontSize: isSmallScreen ? "10.4px" : "body1",
                          }}
                        >
                          {prodItems.subtitles}
                        </Typography>
                        <Box>
                          <List
                            sx={{
                              listStyleType: "disc",
                              listStylePosition: "inside",
                              fontSize: isSmallScreen ? "6.4px" : "body1",
                            }}
                          >
                            {prodItems.bulletins.split(", ").map((item) => (
                              <>
                                <ListItem sx={{ display: "list-item", paddingTop: "0px" }}>
                                  {item}
                                </ListItem>
                              </>
                            ))}
                          </List>
                        </Box>
                        <Box>
                          <Typography
                            variant="h6"
                            sx={{
                              color: "neutral_dark.main",
                              marginY: "5px",
                              fontSize: isSmallScreen ? "8.18px" : "23px",
                            }}
                          >
                            <s>
                              {" "}
                              &#x20b9;
                              {Array.isArray(prodItems.quantity)
                                ? prodItems.quantity[0]?.originalPrice
                                : JSON.parse(prodItems.quantity)[0]
                                    ?.originalPrice}
                              {".00"}
                            </s>{" "}
                            &#x20b9;
                            {Array.isArray(prodItems.quantity)
                              ? prodItems.quantity[0]?.discountPrice
                              : JSON.parse(prodItems.quantity)[0]
                                  ?.discountPrice}
                            {".00"}
                            {".00"}
                          </Typography>
                          <Button
                            component={Link}
                            href="/#/products"
                            sx={{
                              backgroundColor: "btn_acc_2.background",
                              color: "btn_acc_2.main",
                              // padding: "11px 64px",
                              borderRadius: "25px",
                              alignContent: "center",
                              marginTop: isSmallScreen ? "5px" : "25px",
                              width: isSmallScreen ? "74px" : "210px",
                              fontSize: isSmallScreen ? "6px" : "16px",

                              "&:hover": {
                                backgroundColor: "btn_acc_2.background",
                                color: "btn_acc_2.main",
                              },
                            }}
                            onClick={()=>handleselectprod(index)}
                          >
                            {" "}
                            Buy Now
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ borderColor: "neutral_light.main" }}
                    />
                    <Box>
                      <List
                        sx={{
                          display: isSmallScreen
                            ? "block"
                            : isMediumScreen
                            ? "flex"
                            : "block",
                        }}
                      >
                        {features.map((item) => (
                          <>
                            <ListItem
                              sx={{
                                paddingY: isSmallScreen ? "5px" : "",

                                paddingX: isSmallScreen ? "0px" : "",
                              }}
                            >
                              {" "}
                              <Box
                                sx={{
                                  display: isSmallScreen
                                    ? "flex"
                                    : isMediumScreen
                                    ? "block"
                                    : "flex",

                                  paddingY: isSmallScreen ? "0px" : "",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: isSmallScreen
                                    ? "100%"
                                    : isMediumScreen
                                    ? "100%"
                                    : "50%",
                                }}
                              >
                                <Box
                                  sx={{
                                    marginLeft: isSmallScreen ? "15px" : "",
                                    marginRight: isSmallScreen ? "5px" : "15px",
                                    width: isSmallScreen ? "10%" : "fit-content",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: isSmallScreen
                                        ? "15px"
                                        : "fit-content",
                                      height: isSmallScreen ? "15px" : "40.5px",
                                      padding: "1px !important",
                                      border: " 1px solid white !important",
                                      borderRadius: "48% !important",
                                      background: "white !important",
                                    }}
                                  >
                                    <img
                                      src={item.img_url}
                                      width={isSmallScreen ? "15px" : "42px"}
                                    />
                                  </Box>
                                </Box>{" "}
                                <Box
                                  sx={{
                                    marginX: isSmallScreen ? "8px" : "25px",
                                    fontSize: isSmallScreen ? "6.4px" : "body1",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.title}
                                </Box>
                              </Box>
                            </ListItem>
                          </>
                        ))}
                      </List>
                      <Box
                        width={isSmallScreen ? "100%" : 480}
                        sx={{ marginX: isSmallScreen ? "8px" : "16px" }}
                      >
                        <Typography
                        mb={2}
                          variant="p"
                          sx={{
                            padding: "5px 5px",
                            display: "inline",
                            backgroundColor: "accent2_rvt.background",
                            color: "accent2_rvt.main",
                            fontSize: isSmallScreen ? "6.4px" : "body1",
                          }}
                        >
                          Nutrition Information
                        </Typography>

                        <Typography
                          sx={{
                            marginY: isSmallScreen ? "5px" : "5px",
                            fontSize: isSmallScreen ? "6.4px" : "body1",
                          }}
                        >
                          {prodItems.ingredients}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

              ))}
        </Container>
      </Box>
    </>
  );
};

export default HomeProductsSection;


  export const WaveSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 265">
      <path
        fill="#fff"
        fill-opacity="1"
        d="M0,128L120,160C240,192,480,256,720,256C960,256,1200,192,1320,160L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
      ></path>
    </svg>
  );

  // <svg width="100%" height="400" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg">
  //   <defs>
  //   <linearGradient id="greenGradient" x1="0%" y1="0%" x2="0%" y2="100%">
  //       <stop offset="0%" style={{stopColor:"rgb(85,185,48)", stopOpacity:"1"}} />
  //       <stop offset="100%" style={{stopColor:"rgb(143,204,102)",stopOacity:"1"}} />
  //     </linearGradient>
  //   </defs>
  //   <rect width="100%" height="100%" fill="white" />
  //   <g transform="rotate(180, 720, 200)">
  //     <path d="M0,100 Q400,0 1440,100 L1440,400 L0,400 Z" fill="#f5e5cc" />
  //     <path d="M0,200 Q400,100 1440,200 L1440,400 L0,400 Z" fill="url(#greenGradient)" />
  //     <path d="M0,300 Q400,200 1440,300 L1440,400 L0,400 Z" fill="white" />
  //   </g>
  // </svg>
