import React, { useEffect } from "react";
import { Container, Typography, List, ListItem, Box } from "@mui/material";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="lg" sx={{paddingY:10,}}>
      <Box>
        <Typography variant="h4"  textAlign={"center"} >
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          MilletsHeaven is committed to ensuring the privacy and security of your personal
          information. This Privacy Policy outlines how we collect, use, and safeguard your data
          when you visit our website or use our services.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" gutterBottom>
          Information Collection:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              We may collect personal information such as your name, email address, and phone number
              when you place an order or register an account on our website.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          Use of Information:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              We use your personal information to process your orders, communicate with you regarding
              your purchases, and provide customer support.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              Your email address may be used to send you promotional offers, newsletters, and updates
              about our products and services. You can opt-out of receiving these communications at
              any time.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          Data Security:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              We employ industry-standard security measures to protect your personal information from
              unauthorized access, disclosure, alteration, or destruction.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              We restrict access to your personal information to authorized personnel only, who are
              required to keep your information confidential.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          Third-Party Disclosure:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              We do not sell, trade, or otherwise transfer your personal information to third parties
              without your consent, except as required by law or to fulfill your orders.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              Our website may contain links to third-party websites, and our Privacy Policy does not
              apply to their practices. We encourage you to review the privacy policies of these
              websites before providing any personal information.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          Children's Privacy:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              Our website is not intended for children under the age of 13. We do not knowingly
              collect personal information from children, and if we become aware that we have
              inadvertently collected such information, we will take steps to delete it.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="h6" gutterBottom>
          Changes to Privacy Policy:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              We reserve the right to update or modify this Privacy Policy at any time. Any changes
              will be effective immediately upon posting on our website. We encourage you to review
              this Privacy Policy periodically for any updates.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          By using our website or services, you consent to the terms of this Privacy Policy. If you
          have any questions or concerns about our privacy practices, please contact us.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
