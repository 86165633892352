import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Hidden from '@mui/material/Hidden';
import { useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { Context } from '../context/UserContext';

function Navbar() {
  const { myprofile,adminToken } = React.useContext(Context);
  const[pages, setPages]=React.useState([])
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  let location = useLocation();


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const currentRoute=location.pathname

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index );
     handleCloseNavMenu();
  };


  const cartItemCount = myprofile?.cart?.length;

 
 React.useEffect(()=>{
  if(!adminToken){
    setPages( [
     { routename: 'Home', routelink: '/' },
     { routename: 'Products', routelink: '/products' },
     { routename: 'Community', routelink: '/community' },
     { routename: 'About', routelink: '/about' },
     { routename: 'Contact Us', routelink: '/contactus' }
   ])
 }else if(adminToken){
   setPages( [
     { routename: 'OrderList', routelink: '/admin' },
     { routename: 'Products List', routelink: '/admin/productlist' },
     { routename: 'Add Product', routelink: '/admin/addproduct' },
     { routename: 'ContactList', routelink: '/admin/contactuslist' },
   ])
 }
 },[adminToken])

 
  React.useEffect(() => {
    const foundIndex = pages?.findIndex(page => page.routelink === currentRoute);
    setSelectedIndex(foundIndex !== -1 ? foundIndex : null);
  }, [currentRoute, pages]);

  return (
    <AppBar position="static" sx={{ backgroundColor: "primary.main" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <Link
            to={'/'}
            
            >
            <img src={require('../Assets/navlogo.png')} alt="Logo" style={{ height: '40px' }} />

            </Link>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
            {pages.map((page, index) => (
              <Button
                key={page.routename}
                component={Link}
                to={page.routelink}
                onClick={() => handleMenuItemClick(index)}
                sx={{ mx: 2, color: index === selectedIndex ? 'white' : 'black' }}
              >
                {page.routename}
              </Button>
            ))}
          </Box>
          {
            adminToken?'':(<Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* <Link style={{ textDecoration: 'none', color: 'inherit', position: 'relative' }}>
                <IconButton color="inherit" sx={{ color: 'inherit' }}>
                  <SearchIcon style={{ fontSize: '30px' }} />
                </IconButton>
              </Link> */}
              <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit', position: 'relative' }}>
                <IconButton color="inherit" sx={{ color: 'inherit' }}>
                  <AccountCircleIcon style={{ fontSize: '30px' }} />
                </IconButton>
              </Link>
              <Link to="profile/cart" style={{ textDecoration: 'none', color: 'inherit', position: 'relative' }}>
                <IconButton color="inherit" sx={{ color: 'inherit' }}>
                  <ShoppingCartIcon style={{ fontSize: '30px' }} />
                  {cartItemCount >= 0 && (
                    <Typography
                      variant="body1"
                      sx={{
                        position: 'absolute',
                        top: 1,
                        right: 1,
                        backgroundColor: "error.main",
                        color: 'white',
                        borderRadius: '50%',
                        width: '15px',
                        height: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold'
                      }}
                    >
                      {cartItemCount}
                    </Typography>
                  )}
                </IconButton>
              </Link>
            </Box>)
          }
          
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={page.routename}
                  onClick={() => handleMenuItemClick(index)}
                  selected={index === selectedIndex}
                  component={Link}
                  to={page.routelink}
                >
                  <Typography variant='body1' sx={{ color: index === selectedIndex ? 'primary.main' : 'black' }}>
                    {page.routename}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
