import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

const Community = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isSmallScreen, isMediumScreen } = useResponsive();
  const styles = {
    community: {
      padding: "0px !important",
      backgroundImage: 'url("../Assets/communitybg.png")',
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      maxWidth: "100% !important",
      height: "650px", // Adjust height as needed
      display: "flex",
      flexdrection: "row",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff", // Text color for contrast
      margin: "0px",
    },

    youtube_img: {
      width: isSmallScreen ? "100%" : "",
    },
    facebook: {
      backgroundImage: 'url("../Assets/farmimg.png")',

      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      maxWidth: "100% !important",
      height: "700px", // Adjust height as needed
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff", // Text color for contrast
    },
    facebook_img: {
      width: isSmallScreen ? "100%" : "100%",
      height: "35rem",
    },
    main_box: {
      background: "rgba(0,0,0,0.8)",
      height: "100%",
      maxWidth: "100% !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: "0px",
    },
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={styles.community}>
        <Container sx={styles.main_box}>
          <Box
            maxWidth={"100%"}
            sx={{ padding: 0, width: isSmallScreen ? "100%"  :isMediumScreen?"80%": "50%" }}
          >
            <Typography variant="h3" marginY={3}>
              Join Our Community
            </Typography>
            <Typography>
              We are dedicated to promoting a healthier lifestyle through
              natural and nutritious products. With the backing of Unic Health
              Products, we continually strive to innovate and improve our
              offerings, ensuring that Millet Heaven remains your trusted
              partner in health and wellness. Join us on our journey to better
              health and discover the heavenly benefits of millets with Millet
              Heaven. If you have any questions or need further information,
              feel free to reach out to us. We are here to help you embrace a
              healthier, happier life!.{" "}
            </Typography>
            <Button
              sx={{
                backgroundColor: "secondary.main",
                marginY: "30px",
                "&:hover": {
                  backgroundColor: "secondary.main",
                },
                borderRadius: "25px",
                width: "fit-content",
                p: "12px 24px",
                fontSize: 17,
              }}
            >
              <Typography style={{ color: "white", textTransform: "none" }}>
                Get Started
              </Typography>
            </Button>
          </Box>
        </Container>
      </Container>
      <Container>
        <Box sx={{ margin: "20px 20px" }}>
          <iframe
            width="100%"
            height={isSmallScreen ?"auto" : "500px"}
            src="https://www.youtube.com/embed/G7SpwiWtmaI"
            title="Unic Millet Malt | Madhavi Latha |  Milllet Heaven"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Box>
      </Container>
      <Container sx={styles.facebook}>
        <Box sx={{ textAlign: "center" }}>
         { isSmallScreen?(
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61565157620418&tabs=timeline&width=230px&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="230px" height="500"  style={{border:"none", overFlow:"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          ):
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61565157620418&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="500" style={{border:"none", overFlow:"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Community;
