import React, { useState } from 'react';
import { Box, Button, TextField, Typography, CircularProgress, Grid } from '@mui/material';
import axios from 'axios';
import useResponsive from '../../hooks/useResponsive';
import { Phone } from '@mui/icons-material';
import apiUrl from '../../config'; // Adjust path if necessary

const ContactUs = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        address: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateForm = () => {
        const { firstName, lastName, email, mobile, address, message } = formData;
        if (!firstName || !lastName || !email || !mobile || !address || !message) {
            setError('All fields are required.');
            return false;
        }
        if (!validateEmail(email)) {
            setError('Invalid email address.');
            return false;
        }
        if (!/^\d{10}$/.test(mobile)) {
            setError('Phone number must be 10 digits.');
            return false;
        }
        setError(null);
        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}contacts`, formData);
            if (response.data.success === true ) {
                setLoading(false);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    mobile: '',
                    address: '',
                    message: ''
                });
            } else {
                setError(response.data.message || 'Failed to send message.');
            }
        } catch (error) {
            setLoading(false);
            setError('An error occurred while sending your message.');
            console.error(error);
        }
    };

    return (
        <Box sx={{ marginTop: '3rem', backgroundColor: 'primary.main' }}>
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                <Grid item xs={12} md={5}>
                    <Box sx={{ padding: '32px', backgroundColor: '#fff', borderRadius: '8px' }}>
                        <Typography variant="h3" gutterBottom>Contact Us</Typography>
                        <Typography variant="body1" paragraph sx={{ marginY: '25px', fontSize: '20px' }}>
                            Not sure what you need? The team at Millet Heaven will be happy to listen to you and suggest food products for you.
                        </Typography>
                        <Typography variant="body1" sx={{ marginY: '25px', fontSize: '20px' }}>
                            📧 info@milletheaven.com
                        </Typography>
                        <Typography variant="body1" sx={{ marginY: '25px', fontSize: '20px' }}>
                            <Phone /> Support: (+91) 8688392094 / 6281169720
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ padding: '32px', backgroundColor: '#fff', borderRadius: '1rem' }}>
                        <Typography variant="h4" gutterBottom>
                            We'd love to hear from you! Let's get in touch
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="First Name"
                                    fullWidth
                                    variant="outlined"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    error={!!error && !formData.firstName}
                                    helperText={!!error && !formData.firstName ? 'First name is required.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Last Name"
                                    fullWidth
                                    variant="outlined"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    error={!!error && !formData.lastName}
                                    helperText={!!error && !formData.lastName ? 'Last name is required.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    fullWidth
                                    variant="outlined"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={!!error && (!formData.email || !validateEmail(formData.email))}
                                    helperText={!!error && (!formData.email || !validateEmail(formData.email)) ? 'Invalid email address.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Phone Number"
                                    fullWidth
                                    variant="outlined"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    error={!!error && (!formData.mobile || !/^\d{10}$/.test(formData.mobile))}
                                    helperText={!!error && (!formData.mobile || !/^\d{10}$/.test(formData.mobile)) ? 'Phone number must be 10 digits.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Address"
                                    fullWidth
                                    variant="outlined"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    error={!!error && !formData.address}
                                    helperText={!!error && !formData.address ? 'Address is required.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Your Message"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    error={!!error && !formData.message}
                                    helperText={!!error && !formData.message ? 'Message is required.' : ''}
                                />
                            </Grid>
                        </Grid>
                       
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ marginTop: '16px' }}
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Send Message'}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContactUs;
