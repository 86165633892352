import React, { useState, useContext } from "react";
import axios from "axios";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import apiUrl from "../../config";
import { Context } from "../../context/UserContext";
import ProductCard from "./ProductCard";

const ProductList = () => {
  const { products, fetchProducts, adminToken } = useContext(Context);
  const [editProduct, setEditProduct] = useState(null);
  const [title, setTitle] = useState("");
  const [quantity, setQuantity] = useState([
    { weight: "", originalPrice: "", discountPrice: "" },
  ]);
  const [selectedWeight, setSelectedWeight] = useState(0);
  const [ingredients, setIngredients] = useState("");
  const [nutritionInformation, setNutritionInformation] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [bulletins, setBulletins] = useState("");
  const [subtitles, setSubtitles] = useState("");
  const [instock, setInStock] = useState(null);

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(apiUrl + `deleteProduct/${id}`, {
        headers: {
          token: adminToken,
        },
      });
      if (res.status === 200) {
        fetchProducts();
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleEdit = (product) => {
    setEditProduct(product);
    setTitle(product.title);
    setQuantity(product?.quantity);
    setSelectedWeight(product.quantity[0]?.weight || "");
    setIngredients(product.ingredients);
    setNutritionInformation(product.nutritionInformation);
    setSubtitles(product.subtitles);
    setBulletins(product.bulletins);
    setImage(null);
    setImagePreview(product.image);
    setInStock(product.instock);
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("quantity", JSON.stringify(quantity));
      formData.append("ingredients", ingredients);
      formData.append("nutritionInformation", nutritionInformation);
      formData.append("bulletins", bulletins);
      formData.append("subtitles", subtitles);
      formData.append("instock", instock);
      if (image) {
        formData.append("image", image);
      }

      await axios.patch(apiUrl + `updateProduct/${editProduct.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: adminToken,
        },
      });
      setEditProduct(null);
      fetchProducts();
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleQuantityChange = (index, field, value) => {
    const newQuantity = [...quantity];
    if (newQuantity[index]) {
      newQuantity[index][field] = value;
      setQuantity(newQuantity);
    }
  };

  const handleWeightChange = (event) => {
    setSelectedWeight(event.target.value);
  };

  const handleAddQuantity = () => {
    setQuantity([
      ...quantity,
      { weight: "", originalPrice: "", discountPrice: "" },
    ]);
  };

  const handleRemoveQuantity = (index) => {
    const newQuantity = quantity.filter((_, i) => i !== index);
    setQuantity(newQuantity);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImagePreview(null);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <h1>Product List</h1>
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ))}
        <Dialog
          open={!!editProduct}
          onClose={() => setEditProduct(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Edit Product</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={3}>
                <TextField
                  size="small"
                  label="Title"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel sx={{ alignSelf: "center" }}>Stock*</InputLabel>
                  <Select
                    size="small"
                    label="Stock"
                    fullWidth
                    value={instock}
                    onChange={(e) => setInStock(e.target.value)}
                    required
                    sx={{ mb: 2 }}
                  >
                    <MenuItem value={true}>Available Now</MenuItem>
                    <MenuItem value={false}>Out Of Stock</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="SubTitles"
                  fullWidth
                  value={subtitles}
                  onChange={(e) => setSubtitles(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Bulletins"
                  fullWidth
                  value={bulletins}
                  onChange={(e) => setBulletins(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              {Array.isArray(quantity)
                ? quantity.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid container item spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                          <TextField
                            size="small"
                            label="Weight"
                            fullWidth
                            value={item?.weight}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "weight",
                                e.target.value
                              )
                            }
                            required
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            size="small"
                            label="Original Price"
                            fullWidth
                            value={item.originalPrice}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "originalPrice",
                                e.target.value
                              )
                            }
                            required
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            size="small"
                            label="Discount Price"
                            fullWidth
                            value={item.discountPrice}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "discountPrice",
                                e.target.value
                              )
                            }
                            required
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {quantity.length > 1 && (
                          <Grid item xs={12} sm={1}>
                            <IconButton
                              onClick={() => handleRemoveQuantity(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  ))
                : JSON.parse(quantity)?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid container item spacing={2} alignItems="center">
                        <Grid item xs={12} sm={3}>
                          <TextField
                            size="small"
                            label="Weight"
                            fullWidth
                            value={item?.weight}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "weight",
                                e.target.value
                              )
                            }
                            required
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            size="small"
                            label="Original Price"
                            fullWidth
                            value={item.originalPrice}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "originalPrice",
                                e.target.value
                              )
                            }
                            required
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            size="small"
                            label="Discount Price"
                            fullWidth
                            value={item.discountPrice}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                "discountPrice",
                                e.target.value
                              )
                            }
                            required
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {quantity.length > 1 && (
                          <Grid item xs={12} sm={1}>
                            <IconButton
                              onClick={() => handleRemoveQuantity(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleAddQuantity}
                  sx={{ mb: 2 }}
                >
                  Add Quantity
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Ingredients"
                  fullWidth
                  multiline
                  rows={4}
                  value={ingredients}
                  onChange={(e) => setIngredients(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Nutrition Information"
                  fullWidth
                  multiline
                  rows={4}
                  value={nutritionInformation}
                  onChange={(e) => setNutritionInformation(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                {!image && (
                  <Button variant="contained" component="label" sx={{ mb: 2 }}>
                    Upload Image
                    <input
                      type="file"
                      hidden
                      onChange={handleImageChange}
                      accept="image/*"
                    />
                  </Button>
                )}
              </Grid>
              {imagePreview && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        width: "100%",
                        maxHeight: 300,
                        objectFit: "contain",
                        marginTop: 10,
                      }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "#fff",
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditProduct(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ProductList;
