import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Typography, Card, CardContent, CardMedia, Grid, Box } from "@mui/material";
import { Context } from "../../../context/UserContext.js";

const OrderHistory = () => {
    const { myprofile, products } = useContext(Context);
    const [orderlist, setOrderList] = useState([]);

    const reversedOrderlist = useMemo(() => orderlist.slice().reverse(), [orderlist]);

    useEffect(() => {
        setOrderList(myprofile?.orders || []);
    }, [myprofile]);

    return (
        <Box maxWidth="lg" sx={{ mt: 4, mb: 4, width: "100%" }}>
            <Typography variant="h4" component="h2" align="center" gutterBottom>Order History</Typography>
            {orderlist && orderlist.length ? (
                reversedOrderlist.map((order, orderIndex) => {
                    const createdAtDate = new Date(order?.createdAt);
                    const formattedCreatedAt = createdAtDate.toLocaleDateString("en-IN", { day: "2-digit", month: "2-digit", year: "numeric" });
                    const parsedProducts = order?.products;
                    const parsedAddress = order?.address;
                    return (
                        <Card variant="outlined" sx={{ mb: 3 }} key={orderIndex}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">Order Details</Typography>
                                        <Typography variant="h6">Order Id: {order?.orderId}</Typography>
                                        <Typography variant="h6">{formattedCreatedAt}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">Address</Typography>
                                        <Typography variant="h6">{JSON.parse(parsedAddress)?.streetAddress}, {JSON.parse(parsedAddress)?.city}, {JSON.parse(parsedAddress)?.district}, {JSON.parse(parsedAddress)?.state}, {JSON.parse(parsedAddress)?.country} - {JSON.parse(parsedAddress)?.pincode}</Typography>
                                        <Typography variant="h6"><b>Phone Number :</b> {JSON.parse(parsedAddress)?.mobile}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {Array.isArray(parsedProducts) && parsedProducts.map((items, index) => {
                                        const productDetails = products.find((pickle) => parseInt(pickle.id) === parseInt(items.productId));
                                        const totalPrice = items.quantity * parseFloat(items.price);
                                        return (
                                            <Grid item xs={12} sm={6} md={4} key={index}>
                                                <Card variant="outlined">
                                                    {productDetails && (
                                                        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <CardMedia
                                                                component="img"
                                                                image={productDetails.image}
                                                                alt={`Deal ${index + 1}`}
                                                                sx={{ width: 70, height: 60, borderRadius: 1 }}
                                                            />
                                                            <Box sx={{ ml: 2 }}>
                                                                <Typography variant="h6">{productDetails.title}</Typography>
                                                                <Typography variant="h6">{items.price} * {items.quantity} = ₹{totalPrice}</Typography>
                                                            </Box>
                                                        </CardContent>
                                                    )}
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Typography variant="h6">Total Amount: ₹{order?.totalAmount}</Typography>
                            </CardContent>
                        </Card>
                    );
                })
            ) : (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src="/Assets/EmptyCart.svg" width={150} />
                    <Typography variant="h4">No order, Please order something</Typography>
                </Box>
            )}
        </Box>
    );
};

export default OrderHistory;
