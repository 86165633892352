
import NoAddictions from "./Assets/Group 305.png";
import NoSugar from "./Assets/Group 306.png";
import NoSoya from "./Assets/Group 307.png";
import NoGulten from "./Assets/Group 308.png";
import NoColor from "./Assets/Group 309.png";
import Ahar from "./Assets/Ahar.png";
import Almando from "./Assets/Almando.png";
import Millet from "./Assets/Millet.png";
import Raagiwow from "./Assets/Raagiwow.png"

//live server
const apiUrl = "https://serverapi.milletheaven.com/";

//testing 
//const apiUrl = "http://localhost:5001/";

export default apiUrl;

export const features = [
  {
    title: "No Addictions",
    img_url: NoAddictions,
  },
  {
    title: "No Sugar",
    img_url: NoSugar,
  },
  {
    title: "No Soya",
    img_url: NoSoya,
  },
  {
    title: "No Gulten",
    img_url: NoGulten,
  },
  {
    title: "No Color",
    img_url: NoColor,
  },
];

export const products_data = [
  {
    id: 2,
    title: "Baby-Ahar",
    rating: "4.5",
    subtitle: "Make your little one more healthy, Made with grains, nuts and baby herbs",
    bulletins: "100% Natural, Easy digestible, 100% millets",
    quantity: [{ weight: "100g", originalPrice: "290", discountPrice: "200" }, { weight: "100g", originalPrice: "2950", discountPrice: "200" }, { weight: "100g", originalPrice: "250", discountPrice: "200" }],
    ingredients: "Proteins,Potassium, Phosphorous,Sodium, Sodium, Iron, Fiber , Calcium, Zink, folicacid, Thiamin, Copper ,pyridoxamine, Riboflavin, Megnesium, Sodium, Selenium , Cobalt, molybdenum , Nickel",

    img_url: Ahar,
  },
  {
    id: 1,
    title: "Unic Millet Malt",
    rating: "4.5",
    subtitle: "Make your day more healthy, Made with grains,nuts and baby herbs",   
    bulletins: "100% Natural, Easy digestible, 100% millets",
    quantity: [{ weight: "500g", originalPrice: "290", discountPrice: "250" }, { weight: "100g", originalPrice: "250", discountPrice: "200" }, { weight: "100g", originalPrice: "250", discountPrice: "200" }],
    ingredients: "Proteins,Potassium, Phosphorous,Sodium, Sodium, Iron, Fiber , Calcium, Zink, folicacid, Thiamin, Copper ,pyridoxamine, Riboflavin, Megnesium, Sodium, Selenium , Cobalt, molybdenum , Nickel ",

    img_url: Millet,
  },
  {
    id: 3,
    title: "Raagiwow",
    rating: "4.5",
    subtitle: "Bee healthy with more delicious , Made with grains,nuts and row-chocolate",
    bulletins: "100% Natural, Delicious and healthy, 100% millets",
    quantity: [{ weight: "500g", originalPrice: "260", discountPrice: "240" }, { weight: "100g", originalPrice: "250", discountPrice: "200" }, { weight: "100g", originalPrice: "250", discountPrice: "200" }],
    ingredients: "Zink, , Thiamin, Copper ,  Riboflavin, Megnesium , Selenium ,Proteins , Potassium, Phosphorous, Sodium, Sodium, Iron, Fiber , Calcium, Manganese, Niacin",

    img_url: Raagiwow ,
  },
  {
    id:4,
    title: "ALMANDO",
    rating: "4.5",
    subtitle: "Made with almonds, millets, jaggery and herbs",
    bulletins: "100% Natural, Taste the reality of almonds, 100% millets",
    quantity: [{ weight: "400g", originalPrice: "290", discountPrice: "250" }, { weight: "100g", originalPrice: "250", discountPrice: "200" }, { weight: "100g", originalPrice: "250", discountPrice: "200" }],
    ingredients: "Selenium , Cobalt, molybdenum , Nickel  Proteins, Potassium, Phosphorous ,Sodium, Sodium, Iron, Fiber , Calcium, Zink, folicacid, Thiamin, Copper ,pyridoxamine, Riboflavin, Megnesium, Sodium ",

    img_url:Almando ,
  },
]

export const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal"
];
