import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../../config';
import { Box, Typography, TextField, Button } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const { isSmallScreen, isMediumScreen } = useResponsive();


    const sendMail = async () => {
        try {
            const response = await axios.post(`${apiUrl}ForgotPassword`, { email });
            if (response.data.success === true) {
                setSuccess("Password reset link sent successfully.");
                setError("");
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message === "User not found") {
                setError("User not found with this email");
            } else {
                setError("An unexpected error occurred");
            }
            setSuccess("");
        }
    };

    return (
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh", backgroundColor: "#f5f5f5" }}>
        <Box  style={{ width: isSmallScreen ? "100%" : isMediumScreen ? "100%" : "25%", padding: 24, backgroundColor: "white", borderRadius: 8, boxShadow: '0px 0px 15px rgba(0,0,0,0.2)'}}>
            <Typography variant="h4" gutterBottom>Forgot Password</Typography>
            <Typography variant="body1" gutterBottom>Enter your email address below to reset your password.</Typography>

            <TextField
                label="Enter Your Email"
                placeholder="Enter Your Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                fullWidth
                margin="normal"
            />

            {error && <Typography variant='h6' sx={{ color: "red" }}>{error}</Typography>}
            {success && <Typography variant='h6' sx={{ color: "green" }}>{success}</Typography>}

            <Button
                variant="contained"
                color="primary"
                onClick={sendMail}
                sx={{ marginTop: 2 }}
            >
                Send
            </Button>
        </Box>
        </Box>
    );
};

export default ForgotPassword;
